import logo from "../../logo.svg";
import "./style.css";
import idx_islamic_chg from "../../assets/idx_islamic_chg.jpg";
import merdeka78 from "../../assets/merdeka78.jpg";
import CIMB from "../../assets/CIMB.jpg";
import koins from "../../assets/koins.png";
import loading from "../../assets/loading.gif";
import marginTimeline from "../../assets/marginTimeline.jfif";
import kisiMobile from "../../assets/kisiMobile.png";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import promoMarginMain from "../../assets/promoMarginMain.jpg";
import promoEtfMain from "../../assets/promoEtfMain.jpg";
import promoCIMBSyariah from "../../assets/promoCIMBSyariah.png";
import promoCIMBSyariahMobile from "../../assets/promoCIMBSyariahMobile.png";
import etfFee from "../../assets/etfFee.png";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import BlogMenu from "../../components/BlogMenu";
import marginCheck from "../../assets/marginCheck.png";
import regAccInterest from "../../assets/regAccInterest.png";
import marginAccInterest from "../../assets/marginAccInterest.png";
import { getPromos, } from "../../store/action";
import promoReksadana23 from "../../assets/promoReksadana23.jpg";
import { NotFound } from "../NotFound";
import { host, storageLocation } from "../../config/server";
import { server } from "../../config/server";
import payday_2 from "../../assets/payday_2.png";
export function Promo() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const [promoListFromContent, setPromoListFromContent] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [content, setContent] = useState();
  const params = useLocation();
  const currentPath = params.pathname;
  const [imageLoaded, setImageLoaded] = useState(false);
  const getContent = () => {
    fetch(server + `/api/v1/web/promos?slug=${page}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
        access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (result) => {
        console.log(result, "hasil");
        if (result.data.length > 0) {
          setContent(result.data[0]);
        }
        setIsDataFetched(true);
        // console.log(result);
        // console.log(result);
        // await dispatch(setSelectedData(result));
        // const structuredData = await tableDataStructurer(
        //   result,
        //   selectDetails
        // );
        // const { dataToSet, columnToSet } = structuredData;
        // // console.log(dataToSet, "loped", columnToSet);
        // await dispatch(setTableColumns(columnToSet));
        // await dispatch(setTableData(dataToSet));
        // resolve(result);
      })
      .catch((err) => {
        setIsDataFetched(true);
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(page);
    console.log('bijikubijimu');

    // console.log("asw", isDataFetched, promoListFromContent);
    window.scrollTo(0, 0);
    getContent();
  }, [page]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

 

  if (content) {
    return (
      <div class={`promoPageContainer ${imageLoaded ? "o1" : "o0"}`}>
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper py-5">
                <img
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  class="w100 mb-5"
                  src={host + storageLocation + content.image}
                ></img>
                { }
                <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                  {content.title}
                  <p></p>
                </div>
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
          Syarat & Ketentuan menjadi nasabah ETF
          </div> */}
                <div
                  class="etfContent f1r monsterratMedium grey"
                  dangerouslySetInnerHTML={{ __html: content.content }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (isDataFetched) {
      return <NotFound />;
    } else {
      return (
        <div class="w100 text-center center-vertical" style={{ height: 700 }}>
          {/* {JSON.stringify(isDataFetched)} */}
          <img class="mx-auto center-vertical" src={loading}></img>
        </div>
      );
    }
  }
}
