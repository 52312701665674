import searchIcon from "../../assets/searchIcon.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
import "moment/locale/id";
import loading from "../../assets/loading.gif";
import moment from "moment";
export default function Edukasi() {
  const { title, id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [dataPopular, setDataPopular] = useState([]);
  const [dataRelated, setDataRelated] = useState([]);
  const location = useLocation();
  // let isTag;
  // if (state) {
  //   isTag = state.isTag;
  // }
  const dummyTerkait = [
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
    {
      news_title:
        "Contoh title berita terkait yang akan direkomendasikan di samping kanan detail artikel",
      news_date: "05-06-1982",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);

    console.log(`${server}/api/v1/kisiNews/list?${title}`);

    // console.log(type.substring(1, type.length), type);
    // document.getElementById(type).className +=
    //   " subPathActive";

    getData();
    getDataPopular();

    // switch (type) {
    //   case "reguler":
    //     document.getElementById("capping_reguler").className +=
    //       " subPathActive";
    //     document.getElementById("capping_margin").className =
    //       "subPath monsterratMedium rounded-pill mx-3";
    //     break;

    //   case "margin":
    //     // console.log("swituseG");
    //     document.getElementById("capping_margin").className += " subPathActive";
    //     document.getElementById("capping_reguler").className =
    //       "subPath monsterratMedium rounded-pill mx-3";
    //     break;

    //   default:
    //     break;
    // }
    if (window.innerWidth > 768) {
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
    }
  }, [location]);
  const contentProcessor = (content) => {
    // console.log(content);
    var parser = new DOMParser();
    var doc = parser.parseFromString(content, "text/html");
    var paragraphs = doc.querySelectorAll("p");
    const totalParagraphs = paragraphs.length;
    // console.log(paragraphs.length);
    const relatedPosition = Math.ceil(totalParagraphs / 2);
    // console.log(relatedPosition);
    let counter = 0;
    let flag = true;
    let result = "";
    for (let i = 0; i < content.length; i++) {
      const element = content[i];
      const temp =
        content[i - 3] + content[i - 2] + content[i - 1] + content[i];
      // console.log(temp);
      result += element;
      if (temp == "</p>") {
        counter += 1;
      }
      if (counter == relatedPosition && flag == true) {
        const randomRelated =
          dataRelated.length > 0
            ? dataRelated[Math.floor(Math.random() * dataRelated.length)]
            : dataPopular[Math.floor(Math.random() * dataPopular.length)];
        console.log(randomRelated, "rendem", dataRelated);
        const titleLink = randomRelated.news_title.replace(/[?\/]/g, "");
        result += `
        <p>
        <div id='sisipnews' class="sisipnews block768 flex py-3 px-4 bg-biru-muda "><div><span class="monsterratSemiBold nowrap">Baca juga :   &nbsp; </span></div><a class="monsterratSemiBold  blue-text-1 text-decor-none lightBlueHover cursor-pointer" target="_blank" rel="noopener noreferrer" href="${server}/blog/edukasi/${titleLink}/${randomRelated.news_id}">${randomRelated.news_title}</a></div>
        </p>`;
        flag = false;
      }
    }
    // console.log(counter);

    // for (let i = 0; i < content.length; i++) {
    //   const element = content[i];

    // }
    // const contentDiv = document.getElementById("news_content");
    // const paragraphs = contentDiv.getElementsByTagName("p");
    // console.log(contentDiv ,paragraphs);

    return result;
  };
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const getData = () => {
    fetch(
      `${server}/api/v1/kisiNews/list?news_title=${title}&news_id=${id}&with_related_tag=1`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        // body: JSON.stringify(file),
        headers: {
          "Content-Type": "application/json",
          // access_token: localStorage.getItem("access_token"),
        },
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data);

        if (data.status) {
          // console.log(data.data.primary, "ditil");
          data.data.related
            ? setDataRelated(data.data.related.filter((d)=>d.active ==1))
            : console.log("data not found");
          data.data.primary
            ? setData(data.data.primary)
            : console.log("data not found");
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getDataPopular = () => {
    fetch(`${server}/api/v1/kisiNews/popular?limit=5&offset=0`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data, "ditil popular");

        if (data.status) {
          data.data.length > 0
            ? setDataPopular(data.data.filter((d)=>d.active ==1))
            : console.log("data not found");
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  console.log("asemka");

  return (
    <>
      {/* <div class="monsterratExtraBold mt-5 mb-4 mx-auto text-center f275r  blue-text-1 mw90768">
        List Capping Saham
      </div> */}
      <div
        class="subPathWrapper  pb-5"
        style={{
          paddingTop: "0rem",
          marginBottom: 150,
        }}
      >
        {/* {JSON.stringify(data)} */}
        <div class="w70 flex w951000 w851300 mx-auto mt-2 block1000">
          <div className="w80 mb-2 pb-3 mx-auto none-desktop block1000">
            <form
              onSubmit={(e) => {
                navigate(
                  `/blog/edukasi/list/${
                    document.getElementById("search-side-mobile").value
                  }`
                );
              }}
            >
              <input
                className="form-control cursor-pointer px-3 monsterratMedium"
                placeholder="Search"
                style={{
                  borderRadius: 12,
                  borderColor: "#6E6F72",
                  backgroundImage: `url(${searchIcon})`,
                  backgroundSize: "18px",
                  backgroundRepeat: "no-repeat",
                  backgroundPositionX: "94%",
                  backgroundPositionY: "center",
                }}
                id="search-side-mobile"
                // type="search"
                // placeholder="Ketik Untuk mencari"
              ></input>
            </form>
          </div>
          <div class="w100 px-4 mb-5">
            {data.news_id ? (
              <>
                {" "}
                <div class="grey monsterratRegular" style={{ fontSize: 12 }}>
                  {" "}
                  {data.news_source && data.news_source.length > 0 ? (
                    <>
                      By {data.news_source}
                      {" | "}
                    </>
                  ) : null}
                  {moment(data.news_date)
                    .locale("id")
                    .format("dddd, D MMMM YYYY")}
                </div>
                <div
                  class="monsterratBold blue-text-1 mb-3"
                  style={{ fontSize: 32 }}
                >
                  {data.news_title}
                </div>
                <div
                  style={{ fontSize: 16 }}
                  class=" monsterratMedium edukasiContent mb-4"
                  id="news_content"
                  dangerouslySetInnerHTML={{
                    __html: data.news_id ? (
                      contentProcessor(data.news_content)
                    ) : (
                      <div class="w100 text-center">
                        <img src={loading}></img>
                      </div>
                    ),
                  }}
                ></div>
                <div class="w100 monsterratMedium flex flex-wrap">
                  {data.news_tag
                    ? JSON.parse(data.news_tag).map((tag, i) => {
                        return (
                          <div
                            class="py-1 mb-2 news-tag-button blue-text-1 px-3"
                            onClick={(e) => {
                              navigate(`/blog/edukasi/list/${tag}`, {
                                state: { isTag: true },
                              });
                            }}
                            style={{
                              marginRight: 20,
                              border: "1px solid #0071BF",
                              borderRadius: 10,
                              cursor: "pointer",
                            }}
                          >
                            {tag}
                          </div>
                        );
                      })
                    : null}
                </div>
              </>
            ) : null}
          </div>
          <div
            class="px-4 w1001000 mw1001000"
            style={{ width: 500, maxWidth: "40%" }}
          >
            <div className="w100 mb-2 pb-3 none1000">
              <form
                onSubmit={(e) => {
                  navigate(
                    `/blog/edukasi/list/${
                      document.getElementById("search-side").value
                    }`
                  );
                }}
              >
                <input
                  className="form-control cursor-pointer px-3 monsterratMedium"
                  placeholder="Search"
                  style={{
                    borderRadius: 12,
                    borderColor: "#6E6F72",
                    backgroundImage: `url(${searchIcon})`,
                    backgroundSize: "18px",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "94%",
                    backgroundPositionY: "center",
                  }}
                  id="search-side"
                  // type="search"
                  // placeholder="Ketik Untuk mencari"
                ></input>
              </form>
            </div>
            <div class="w100 ">
              <div class="monsterratBold  mb-3" style={{ fontSize: 18 }}>
                Berita Terpopuler
              </div>
              <div class="w100">
                {dataPopular.map((n, i) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      class="my-3"
                      onClick={(e) => {
                        const result = n.news_title.replace(/[?\/]/g, "");
                        navigate(`/blog/edukasi/${result}/${n.news_id}`);
                      }}
                    >
                      <span
                        class="f mb-1 blue-text-1 monsterratMedium preview2line"
                        style={{ fontSize: 18 }}
                      >
                        {n.news_title}
                      </span>
                      <div
                        class="grey monsterratRegular"
                        style={{ fontSize: 12 }}
                      >
                        {n.news_source && n.news_source.length > 0 ? (
                          <>
                            By {n.news_source}
                            {" | "}
                          </>
                        ) : null}
                        {moment(n.news_date)
                          .locale("id")
                          .format("dddd, D MMMM YYYY")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
