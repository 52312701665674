import { useNavigate } from "react-router-dom";
import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNews,
  setSortedNews,
  getNewsById,
  setNewsDetail,
} from "../../store/action";
import BlogMenu from "../../components/BlogMenu";
import loading from "../../assets/loading.gif";
import { useParams } from "react-router-dom";
import fbLogo from "../../assets/fb.ico";
import instagramLogo from "../../assets/instagram.ico";
import twitterLogo from "../../assets/twitter.png";
import linkedinLogo from "../../assets/linkedin.ico";
import tiktokLogo from "../../assets/tiktok.ico";
export function ArtikelById() {
  let { news_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sortedNews = useSelector((state) => state.sortedNews);
  const [news, setNews] = useState({});
  // const news = useSelector((state) => state.news);
  // const newsDetail = useSelector((state) => state.newsDetail);
  const [localNewsDetail, setLocalNewsDetail] = useState({});
  // const newsDetail = useSelector((state) => state.newsDetail);
  useEffect(() => {
    window.scrollTo(0, 1);
    console.log(news_id);
    // document.getElementById("navBar").style.boxShadow =
    // "0px 4px 14px rgb(0 0 0 / 7%)";
    loadPage();
  }, []);
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  const loadPage = () => {
    dispatch(setNewsDetail({}));

    if (sortedNews.length === 0) {
      // console.log("masuk if no news");
      dispatch(getNews())
        .then((news) => {
          var sortedNews = [].concat(news);
          sortedNews.sort((a, b) => {
            return b.views - a.views;
          });
          dispatch(setSortedNews(sortedNews));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    let isArticleAvailable = false;
    // for (let i = 0; i < news.length; i++) {
    //   if (news[i].id === Number(news_id)) {
    //     dispatch(setNewsDetail(news[i]));
    //     setLocalNewsDetail(news[i]);
    //   }
    // }

    dispatch(getNewsById(news_id))
      .then((result) => {
        if (result.error) {
          throw result;
        } else {
          // setNewsDetail(result); //
          dispatch({ type: "SET_NEWS_DETAIL", payload: result });
          setNews(result.data);
        }
      })
      .catch((error) => {
        navigate("/404");
      });
  };

  const goToArticle = (id) => {
    console.log("masuk");

    loadPage();
    navigate(`/artikel/${id}`);
  };
  const MainArticle = () => {

      return (
        <div id="newsDetail"class="newsByIdDiv">
          {/* {JSON.stringify(news)} */}
          <div class="monsterratExtraBold blue-text-1 f25r newsDetailTitle">
            
            {news.title}
          </div>
          <div class="my-5 pb-4">
            <div class="monsterratBold grey mx-auto textCenter mt-3">
              Follow Social Media
            </div>
            <div class="followSocmedImgContainer socmedFooter">
              <img
                class="socmedFooterImg artikelSocmed"
                src={instagramLogo}
                onClick={() => {
                  openInNewTab("https://www.instagram.com/kisi_sekuritas/");
                }}
              />
              <img
                class="socmedFooterImg artikelSocmed"
                src={tiktokLogo}
                onClick={() => {
                  openInNewTab("hhttps://www.tiktok.com/@kisi_sekuritas");
                }}
              />
              <img
                class="socmedFooterImg artikelSocmed "
                src={fbLogo}
                onClick={() => {
                  openInNewTab("https://www.facebook.com/koreainvestment.id/");
                }}
              />
              <img
                class="socmedFooterImg artikelSocmed "
                src={linkedinLogo}
                onClick={() => {
                  openInNewTab(
                    "https://www.linkedin.com/company/korea-investment-and-sekuritas-indonesia-pt/mycompany/"
                  );
                }}
              />

              <img
                class="socmedFooterImg artikelSocmed"
                src={twitterLogo}
                onClick={() => {
                  openInNewTab("https://x.com/kisi_sekuritas");
                }}
              />
            </div>
          </div>
          <div
            class="mainArticleContainer2 flex"
            style={{
              backgroundImage: `url(${news.images})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div style={{ alignSelf: "flex-end" }}>
              <div class="flex monsterratRegular white mainArticleSourceDate">
                <div>{news.published_at}</div>
              </div>
            </div>
          </div>
          {/* <div class="newsDetailSource monsterratRegular grey">
            By {localNewsDetail.source}
          </div> */}
        </div>
      );
    // } else {
    //   return (
    //     <div
    //       class="mainArticleContainer flex"
    //       style={{
    //         backgroundImage: `url(${loading})`,
    //         backgroundRepeat: "no-repeat",
    //         backgroundSize: "auto",
    //       }}
    //     ></div>
    //   );
    // }
  };

  const ArticleContent = () => {
    if (news.news_id !== undefined) {
      return (
        <div class="newsContentContainer f13r  monsterratRegular grey">
          <pre class="formattedArticleContent monsterratMedium">
            {" "}
            {news.content}
          </pre>
        </div>
      );
    }
  };
  return (
    <div>
      {/* <BlogMenu from="artikelById"></BlogMenu> */}
  
      <MainArticle />
   
      <div class="articleContentContainer">
        <div class="articleContentWrapper">
          <ArticleContent />
        </div>
 
      </div>
    </div>
  );
}
