import React, { useEffect, useState } from "react";
import PageLocation from "../../../components/PageLocation";
import etfFee from "../../../assets/etfFee.png";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import waranTerstruktur from "../../../assets/waranTerstruktur.png";
import FollowSocmed from "../../../components/FollowSocmed";
import { useNavigate, useLocation } from "react-router-dom";
import Aos from "aos";
import { storageLocation } from "../../../config/server";
import swCode from "../../../assets/swCode.png";
import faqArrow from "../../../assets/faqArrow.png";
import bondLogo from "../../../assets/bondLogo.png";
import bondTerm1 from "../../../assets/bondTerm1.png";
import bondTerm2 from "../../../assets/bondTerm2.png";
import bondTerm3 from "../../../assets/bondTerm3.png";
import bondTerm4 from "../../../assets/bondTerm4.png";
import bondTerm5 from "../../../assets/bondTerm5.png";
import bondTerm6 from "../../../assets/bondTerm6.png";
import bondImg from "../../../assets/bondImg.png";
import bondImgMobile from "../../../assets/bondImgMobile.png";
import { server } from "../../../config/server";
import moment from "moment";
import { get } from "jquery";
import close from "../../../assets/close.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import bondsBanner from "../../../assets/bondsBanner.png";
import bondsBannerMobile from "../../../assets/bondsBannerMobile.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "swiper/css/loop";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Autoplay, Mousewheel } from "swiper/modules";
export function Bonds({}) {
  const { state } = useLocation();
  let toAnnouncement = false;
  const [data, setData] = useState([]);
  const [newSwData, setNewSwData] = useState([]);
  const [expiredSwData, setExpiredSwData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const MySwal = withReactContent(Swal);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // console.log(state);
  if (state) {
    toAnnouncement = state.toAnnouncement;
  }

  useEffect(() => {
    Aos.init({ duration: 2000 });
    getData();
    // detailModal();
  }, [sortBy]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(
    //   document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width
    // ,window.innerWidth);
    // if (window.innerWidth >= 1660) {
    //   const onScroll = () => setOffset(window.pageYOffset);
    //   // clean up code
    //   window.removeEventListener("scroll", onScroll);
    //   window.addEventListener("scroll", onScroll, { passive: true });
    //   // console.log(
    //   //   document.documentElement.offsetHeight,
    //   //   "hilih",
    //   //   document.getElementById(`data_waran_end`).getBoundingClientRect()
    //   // );
    //   // console.log( document.getElementById(`th_waran_top`), document
    //   // .getElementById(`tabel_data_waran_wrapper`)
    //   // .getBoundingClientRect().width);
    //   document.getElementById(`th_waran_top`).style.width = document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width;
    //   document.getElementById(`th_waran_top`).style.width = document
    //     .getElementById(`tabel_data_waran_wrapper`)
    //     .getBoundingClientRect().width;
    //   var tableHeadTop = document.getElementById(`th_waran_top`);
    //   var startShow = document
    //     .getElementById(`th_waran_static`)
    //     .getBoundingClientRect();
    //   var navbarH = document
    //     .getElementById(`navBar`)
    //     .getBoundingClientRect().height;
    //   var subpathH = document
    //     .getElementById(`subPath`)
    //     .getBoundingClientRect().height;
    //   var tableEnd = document
    //     .getElementById(`data_waran_end`)
    //     .getBoundingClientRect();
    //   // console.log(startShow, navbarH, subpathH);
    //   tableHeadTop.style.top = `${navbarH + subpathH}px`;
    //   document.getElementById(`th_waran_top`).style.width = `${
    //     document
    //       .getElementById(`tabel_data_waran_wrapper`)
    //       .getBoundingClientRect().width
    //   }px`;
    //   if (
    //     startShow.top <= navbarH + subpathH &&
    //     tableEnd.top >
    //       navbarH +
    //         subpathH +
    //         document.getElementById(`th_waran_static`).getBoundingClientRect()
    //           .height
    //   ) {
    //     tableHeadTop.style.display = "block";
    //     tableHeadTop.style.top = navbarH + subpathH;
    //   } else {
    //     tableHeadTop.style.display = "none";
    //   }
    //   return () => window.removeEventListener("scroll", onScroll);
    //   console.log(document.documentElement.offsetHeight, "hilih");
    // }
  }, [offset]);
  const getData = () => {
    fetch(`${server}/api/v1/fixedincome/list${sortBy}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        console.log(data, "okegas");
        setData(data.data.filter((item) => item.active == 1));
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const detailModal = (b) => {
    const detailModal = MySwal.mixin({
      // toast: true,
      allowOutsideClick: false,

      showClass: {
        popup: " bondPopup center-vertical",
      },
      hideClass: {
        popup: " fadeOutUp faster",
      },
      showConfirmButton: false,
      showLoaderOnConfirm: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
      backdrop: true,
      allowOutsideClick: false,
      html: (
        <div className="bond-card__container">
          <div className="bond-card__header">
            <div className="bond-card__header-left" style={{ gap: 15 }}>
              <div className="bond-card__icon">
                {/* Replace with actual SVG/image */}
                <div class="bond-logo">
                  <img class="logo-circle" src={bondLogo} />
                </div>
              </div>
              <div className="bond-card__title">
                <p
                  className="bond-card__series monsterratRegular pb-2"
                  style={{ fontSize: 14, color: "black" }}
                >
                  Seri Obligasi
                </p>
                <h1
                  className="bond-card__series-code monsterratBold"
                  style={{ fontSize: 24 }}
                >
                  {b.bond_code}
                </h1>
              </div>
            </div>
            <button
              className="bond-card__close-button"
              onClick={(e) => {
                MySwal.close();
              }}
            >
              {" "}
              &times;
            </button>
          </div>
          <div className="bond-card__details">
            <div
              className="bond-card__details-left"
              style={{ textAlign: "left" }}
            >
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Nama
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {b.bond_name}
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Kategori
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {b.bond_category}
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Rating
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {b.rating.length > 0 ? <>{b.rating}</> : "-"}
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Jatuh Tempo
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {moment(b.maturity_date).format("DD-MM-YYYY")}
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Pembayaran
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {b.coupon_payment}
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Jenis Kupon
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {b.coupon_type}
                </span>{" "}
              </p>
            </div>
            <div className="bond-card__details-right">
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Kupon (% p.a)
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {b.coupon_rate}
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Kami Jual
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {b.sell_percent}%
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Yield
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {b.sell_yield}%
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Kami Beli
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {b.buy_percent}%
                </span>{" "}
              </p>
              <p>
                <span class="monsterratSemiBold" style={{ fontSize: 14 }}>
                  Yield
                </span>
                <br />
                <span style={{ fontSize: 14 }} class="monsterratRegular">
                  {" "}
                  {b.buy_yield}%
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      ),
      showConfirmButton: false,
      heightAuto: false,
    });
    detailModal.fire();
  };

  const handleSort = (sortby) => {
    setSortBy(sortby);
    getData();
  };
  const dummy = [
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      kode: "FR0056",
      nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Korporasi",
      Kode: "FMFN2B",
      ama: "Obligasi I KB FInansia Multifinance Tahun",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "3.38%",
      coupon_payment: "Setiap 3 Bulan",
      expiry: "2026-09-15",
      buy: "101.60%",
      yield: "6.7657%",
      sell: "103.66%",
      yield_sell: "5.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
    {
      kategori: "Negara",
      Kode: "FR0056",
      Nama: "Obligasi Negara Republik Indonesia Seri FR0056",
      rating: "AAA(idn)",
      jenis_kupon: "Fixed",
      coupon_rate: "8.38%",
      coupon_payment: "Setiap 6 Bulan",
      expiry: "2026-09-15",
      buy: "103.60%",
      yield: "7.7657%",
      sell: "104.66%",
      yield_sell: "6.8874%",
    },
  ];
  // let filteredData = [];
  // if (data !== null) {
  //   filteredData = data;
  // }
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US";
    }

    return "https://apps.apple.com/id/app/kisi-mobile/id1493151464";
  };
  return (
    <div className="o1 mt-4">
      <div class="imageDivWrapper w80 px-5 ">
        {" "}
        <img
          class="w100 pointer none-mobile"
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={bondsBanner}
          // onClick={(e) => navigate("/kisimobile")}
        ></img>{" "}
        <img
          class="w100 pointer none-desktop block768"
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={bondsBannerMobile}
          // onClick={(e) => navigate("/kisimobile")}
        ></img>
        {/* <img
          onLoad={() => {
            setImageLoaded(true);
          }}
          data-aos="fade-up"
          data-aos-duration="2000"
          src={margin}
          alt="banner image"
          class={`imageZ1 marginImg`}
        />
        <div class="insideWrapperAbsolute centerAbsolute768 sahamImgDiv marginDownloadDiv">
          <div class="downloadButtonGroup">
            <div class=" etfDownloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                onClick={() => navigate("/kisimobile")}
              >
                DOWNLOAD SEKARANG
              </button>

              <div class="flex mobileMarketplaceContainer">
                <div class="  flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
                <div class="  flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="insideWrapperAbsolute sahamBannerText marginBannerText">
          <div class="sahamBannerTitle monsterratBlack marginBannerTitle">
            <span style={{ color: "#fbb03c" }}>
              PROMO MARGIN <br/></br> ACCOUNT
            </span>
            <br/></br>
            <div>
              <span class="f9r">12%</span>
              <span class="f15r">/tahun</span>
            </div>
            <div class="f1r marginPersenImg">
              <span>
                <img class="marginCheck" src={marginCheck}></img> Fee Beli 0.13%
                dan Jual 0.23%
              </span>{" "}
              <span>
                <img class="marginCheck" src={marginCheck}></img> 100+ pilihan
                saham
              </span>
              <div>
                {" "}
                <span>
                  <img class="marginCheck" src={marginCheck}></img> Periode
                  promosi Januari s/d Desember 2023
                </span>
              </div>
            </div>

            <p class="sahamBannerSubtitle"></p>
          </div>
        </div> */}
      </div>
      <div class="reksadanaUpperContainer mb-5">
        <div class="monsterratExtraBold  f275r mb-3 blue-text-1">
          Daftar Bonds
        </div>
        {/* <div class="monsterratMedium mt-2 f15r blue-text-1">
          Fund by KISI Mobile
        </div> */}
        <div class="monsterratMedium reksadanaDescriptionTxt grey jenisAkunText mt-3 f1r w80">
          Pilihan Bonds untuk solusi investasi anda
        </div>
        {/* <div class="monsterratMedium grey jenisAkunText mt-2 f1r">
          Untuk dapat melakukan transaksi Reksa Dana silahkan download Aplikasi
          KISI Mobile
        </div> */}
      </div>
      {/* <FollowSocmed /> */}
        <div
          class="px-4 mx-auto swiperBondContainer"
          style={{ height:  data.length<3 ? data.length*150 :400, }}
        >
        <Swiper
          direction="vertical" // Makes the swiper vertical
          // loop={true} // Enables infinite looping
          slidesPerView={3} // Display 3 items per slide
          // spaceBetween={3} // Space between slides (optional)
          mousewheel={true}
          // breakpoints={{
          //   // Responsive breakpoints
          //   1000: {
          //     slidesPerView: 3, // 3 slides for screens 1000px and above
          //   },
          //   0: {
          //     slidesPerView: 1, // 2 slides for screens below 1000px
          //   },
          // }}
          autoplay={{
            // Autoplay configuration
            delay: 2500, // Delay between slides in milliseconds
            disableOnInteraction: false, // Continue autoplay after interaction
          }}
          pagination={{ clickable: true }} // Adds pagination dots
          // scrollbar={{draggable:true}}
          // navigation={true}    // Adds navigation arrows
          modules={[Navigation, Pagination, Autoplay, Mousewheel]} // Include necessary modules
          style={{ height: "100%",justifyContent:'space-around' }}
          className="swiper-bond"
        >
          {/* Add multiple slides */}
          {data.map((d, i) => {
            return (
              <SwiperSlide
                style={{
                  // background: "#FF6F61",
                  // color: "white",
                  // fontSize: "24px",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  margin: "auto",
                }}
              >
                <div
                  className="bond-card  mx-auto py-4 "
                  onClick={(e) => {
                    detailModal(d);
                  }}
                >
                  <div className="bond-header " style={{ gap: 30 }}>
                    <div className="bond-logo ">
                      <div className="logo-circle mx-auto">
                        <img src={bondLogo} alt="Logo" />
                      </div>
                    </div>
                    <div className="bond-info text-center-1000">
                      <div className="label monsterratRegular">
                        Seri Obligasi
                      </div>
                      <div
                        className="bond-series monsterratBold"
                        style={{ fontSize: 32, lineHeight: 1.3 }}
                      >
                        {d.bond_code}
                      </div>
                    </div>
                  </div>

                  <div className="bond-details ">
                  <div className="bond-detail">
                      <span className="label monsterratRegular">
                        Kupon (% p.a)
                      </span>
                      <span
                        className="value monsterratMedium"
                        style={{ fontSize: 20 }}
                      >
                        {Number(d.coupon_rate).toFixed(4)}%
                      </span>
                    </div>
                    <div className="bond-detail">
                      <span className="label monsterratRegular">Kami Beli</span>
                      <span
                        className="value monsterratMedium"
                        style={{ fontSize: 20 }}
                      >
                        {Number(d.buy_percent).toFixed(2)}%
                      </span>
                    </div>
                    <div className="bond-detail">
                      <span className="label monsterratRegular">Kami Jual</span>
                      <span
                        className="value monsterratMedium"
                        style={{ fontSize: 20 }}
                      >
                        {Number(d.sell_percent).toFixed(2)}%
                      </span>
                    </div>
                    
                  
                    <div className="bond-detail">
                      <span className="label monsterratRegular">Yield</span>
                      <span
                        className="value highlight monsterratBold"
                        style={{ fontSize: 32, lineHeight: 1.3 }}
                      >
                        {Number(d.sell_yield).toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {/* {data.map((d, i) => {
        return (
          <div
            className="bond-card   py-4 mw901000"
            onClick={(e) => {
              detailModal(d);
            }}
          >
            <div className="bond-header block1000" style={{ gap: 30 }}>
              <div className="bond-logo ">
                <div className="logo-circle mx-auto">
                  <img src={bondLogo} alt="Logo" />
                </div>
              </div>
              <div className="bond-info">
                <div className="bond-title monsterratRegular">
                  Seri Obligasi
                </div>
                <div
                  className="bond-series monsterratBold"
                  style={{ fontSize: 32, lineHeight: 1.3 }}
                >
                  {d.bond_code}
                </div>
              </div>
            </div>

            <div className="bond-details block1000">
              <div className="bond-detail">
                <span className="label monsterratRegular">Jual</span>
                <span
                  className="value monsterratMedium"
                  style={{ fontSize: 20 }}
                >
                  {Number(d.sell_percent).toFixed(2)}%
                </span>
              </div>
              <div className="bond-detail">
                <span className="label monsterratRegular">Beli</span>
                <span
                  className="value monsterratMedium"
                  style={{ fontSize: 20 }}
                >
                  {Number(d.buy_percent).toFixed(2)}%
                </span>
              </div>
              <div className="bond-detail">
                <span className="label monsterratRegular">Kupon (% p.a)</span>
                <span
                  className="value monsterratMedium"
                  style={{ fontSize: 20 }}
                >
                  {Number(d.coupon_rate).toFixed(2)}%
                </span>
              </div>
              <div className="bond-detail">
                <span className="label monsterratRegular">Yield</span>
                <span
                  className="value highlight monsterratBold"
                  style={{ fontSize: 32, lineHeight: 1.3 }}
                >
                  {Number(d.sell_yield).toFixed(2)}%
                </span>
              </div>
            </div>
          </div>
        );
      })} */}
      {/* <div className="bond-card mt-2 py-4">
        <div className="bond-header">
          <div className="bond-logo">
            <div className="logo-circle">
              <img src={bondLogo} alt="Logo" />
            </div>
          </div>
          <div className="bond-info">
            <div className="bond-title monsterratRegular">Seri Obligasi</div>
            <div
              className="bond-series monsterratBold"
              style={{ fontSize: 32, lineHeight: 1.3 }}
            >
              FR0056
            </div>
          </div>
        </div>

        <div className="bond-details block1000">
          <div className="bond-detail">
            <span className="label monsterratRegular">Jual</span>
            <span className="value monsterratMedium" style={{ fontSize: 20 }}>
              104.00%
            </span>
          </div>
          <div className="bond-detail">
            <span className="label monsterratRegular">Beli</span>
            <span className="value monsterratMedium" style={{ fontSize: 20 }}>
              103.60%
            </span>
          </div>
          <div className="bond-detail">
            <span className="label monsterratRegular">Kupon (% p.a)</span>
            <span className="value monsterratMedium" style={{ fontSize: 20 }}>
              8.38
            </span>
          </div>
          <div className="bond-detail">
            <span className="label monsterratRegular">Yield</span>
            <span
              className="value highlight monsterratBold"
              style={{ fontSize: 32, lineHeight: 1.3 }}
            >
              8.38%
            </span>
          </div>
        </div>
      </div> */}
      {/* <div
        class="w90 mx-auto mb-5 pb-5  "
        id="tabel_data_waran_wrapper"
        style={{ overflow: "auto" }}
      >
        <table
          id="tabel_data_waran_1"
          className="table  table-striped table-warrant"
          style={{ textAlign: "center", borderLeft: "0px solid transparent" }}
        >
          

          <thead
            id="th_waran_static"
            style={{
              border: "none !important  ",
            }}
          >
           

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className="  monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical  justify-content-between  justify-content-center"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Kategori</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/bond_category/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/bond_category/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center">
                  <span>Kode</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between  justify-content-center h100 w100">
                  <span>Nama</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_name/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/bond_name/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Rating</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/rating/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/rating/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jenis Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_type/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_type/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kupon (% p.a)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_rate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_rate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Pembayaran Kupon</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_payment/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/coupon_payment/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/maturity_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        handleSort("/maturity_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Beli</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_percent/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_percent/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_yield/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/buy_yield/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Kami Jual</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_percent/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_percent/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran  monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical  justify-content-between">
                  <span>Yield</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_yield/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer none"
                      onClick={(e) => {
                        setSortBy("/sell_yield/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
            </tr>
          </thead>
          <tbody id="formData tableWarrantFontContent">
            {data.map((data, i) => {
              return (
                <tr className="form_c" id={`data_waran_${i}`}>
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a>{data.bond_category}</a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.bond_code}
                  </td>
                  <td
                    className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb"
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {data.bond_name}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.rating}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_type}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_rate}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.coupon_payment}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.maturity_date ? (
                      moment(data.maturity_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.buy_percent}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.buy_yield}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.sell_percent}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.sell_yield}
                  </td>

               
                </tr>
              );
            })}
            {[[]].map((data, i) => {
              return (
                <tr className="form_c" id="data_waran_end">
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a
                      onClick={() =>
                        this.getRicToDetails("KISIDRCJ4A", "KISIDRCJ4A")
                      }
                    >
                      {data.categpry}
                    </a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.issuer_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.base_stock_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.PutOrCall}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.BaseStock_LastPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_price}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_ratio}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.expiry_date ? (
                      moment(data.expiry_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.maturity_days}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.warrant_sensitivity_ticks}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.effective_gearing}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}

      <div class="py-5 mt-5  mx-auto text-center mw90 " style={{ width: 1100 }}>
        <div class="flex pb-3 block768">
          <div class="">
            <img class="mb-3 none-mobile" src={bondImg}></img>
            <img
              class="mb-3 none-desktop block768"
              style={{ width: "100%" }}
              src={bondImgMobile}
            ></img>
          </div>
          <div class="px-5">
            <div>
              <div
                class="monsterratBold f25r mb-3 blue-text-1 nowrap wrap1000"
                style={{ fontSize: 36 }}
              >
                Apakah Obligasi (Bond) itu ?
              </div>
            </div>
            <div
              class="monsterratRegular  mt-3 mb-5 text-justify text-center-768"
              style={{ textAlign: "justify", fontSize: 16 }}
            >
              Obligasi adalah surat utang yang dapat dipindahtangankan, yang
              berisi janji dari pihak yang menerbitkan untuk membayar imbalan
              berupa bunga pada periode tertentu dan melunasi pokok utang pada
              waktu yang telah ditentukan kepada pihak pembeli obligasi
              tersebut.
            </div>
          </div>
        </div>

        <div
          class="monsterratBold f25r my-5 blue-text-1"
          style={{ fontSize: 36 }}
        >
          Beberapa istilah terkait Obligasi
        </div>
        <div class="bondIstilahDiv">
          <div class="bondIstilah mb-4 text-left-768">
            <div
              class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1 pl0768"
              style={{ fontSize: 16 }}
            >
              <div>
                <img src={bondTerm1} class="bondTermImg" />
              </div>
              Nilai Nominal (Face Value)
            </div>
            <div
              class="monsterratRegular  mt-2  px-4 pl0768"
              style={{ fontSize: 16 }}
            >
              Nilai pokok dari suatu obligasi yang akan diterima oleh pemegang
              obligasi pada saat obligasi tersebut jatuh tempo.
            </div>
          </div>
          <div class="bondIstilah mb-4 text-left-768">
            <div
              class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1 pl0768"
              style={{ fontSize: 16 }}
            >
              <div>
                <img src={bondTerm2} class="bondTermImg" />
              </div>
              Kupon (Interest Rate)
            </div>
            <div
              class="monsterratRegular  mt-2  px-4 pl0768"
              style={{ fontSize: 16 }}
            >
              Nilai bunga yang diterima pemegang obligasi secara berkala
              (biasanya setiap 3 atau 6 bulanan).
            </div>
          </div>

          <div class="bondIstilah mb-4 text-left-768">
            <div
              class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1 pl0768"
              style={{ fontSize: 16 }}
            >
              <div>
                <img src={bondTerm3} class="bondTermImg" />
              </div>
              Bunga Berjalan (Accrued Interest)
            </div>
            <div
              class="monsterratRegular  mt-2  px-4 pl0768"
              style={{ fontSize: 16 }}
            >
              Total dari kupon harian yang akan diterima oleh investor selama
              memegang kepemilikan pada obligasi.
            </div>
          </div>
          <div class="bondIstilah mb-4 text-left-768">
            <div
              class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1 pl0768"
              style={{ fontSize: 16 }}
            >
              <div>
                <img src={bondTerm4} class="bondTermImg" />
              </div>
              Imbal hasil (Yield)
            </div>
            <div
              class="monsterratRegular  mt-2  px-4 pl0768"
              style={{ fontSize: 16 }}
            >
              Pengembalian yang diperoleh investor atas modal yang
              diinvestasikan untuk membeli obligasi.
            </div>
          </div>
          <div class="bondIstilah mb-4 text-left-768">
            <div
              class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1 pl0768"
              style={{ fontSize: 16 }}
            >
              <div>
                <img src={bondTerm5} class="bondTermImg" />
              </div>
              Jatuh Tempo (Maturity)
            </div>
            <div
              class="monsterratRegular  mt-2  px-4 pl0768"
              style={{ fontSize: 16 }}
            >
              Tanggal saat  pemegang obligasi akan mendapatkan pembayaran
              kembali pokok atau Nilai Nominal obligasi yang dimilikinya. Adapun
              periode jatuh tempo obligasi bervariasi mulai dari 365 hari sampai
              dengan diatas 20 tahun.
            </div>
          </div>

          <div class="bondIstilah mb-4 text-left-768">
            <div
              class="monsterratSemiBold  mt-4 f1r mb-2 blue-text-1 pl0768"
              style={{ fontSize: 16 }}
            >
              <div>
                <img src={bondTerm6} class="bondTermImg" />
              </div>
              Penerbit (Issuer)
            </div>
            <div
              class="monsterratRegular  mt-2  px-4 pl0768"
              style={{ fontSize: 16 }}
            >
              pihak yang menerbitkan obligasi, yaitu  pihak perusahaan atau
              pemerintah yang berutang pada investor sebagai pemegang obligasi
              yang diterbitkannya. 
            </div>
          </div>
        </div>
      </div>
      <div className="faqDiv">
        <div className="faqText">
          <div className="monsterratExtraBold f2r mx-auto  mw90768 grey textCenter blue-text-1 f2r">
            Keuntungan berinvestasi pada obligasi
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Obligasi dapat dijual sebelum jatuh tempo
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Karena diperdagangkan di pasar sekunder maka obligasi dapat
                  dijual sebelum jatuh tempo. Khususnya Obligasi Negara FR yang
                  sangat likuid oleh karena itu dapat dijual kapan saja tanpa
                  harus tunggu jatuh tempo.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Memberikan pendapatan tetap (fixed income) berupa kupon atau
                  bunga.
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Kupon atau bunga dibayarkan secara regular sampai dengan jatuh
                  tempo dan ditetapkan dalam persentase dari nilai nominal dan
                  merupakan passive income bagi pemegang obligasi. Perhitungan
                  kupon dilakukan secara akumulasi setiap hari dari saat
                  obligasi dibeli hingga jatuh tempo. Jika obligasi dijual
                  sebelum jatuh tempo, investor sebagai penjual tetap mendapat
                  pembayaran kupon sesuai dengan lamanya waktu obligasi
                  dimiliki. Tingkat bunga atau kupon obligasi biasanya lebih
                  tinggi dari tingkat bunga yang ditawarkan deposito perbankan.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Imbal hasil (yield) yang sudah tetap
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Investor bisa mengunci imbal hasil (yield) yang akan diterima
                  sejak obligasi dibeli hingga jatuh tempo (yield-to-maturity).
                  Imbal hasil obligasi biasanya di atas suku bunga deposito dan
                  tidak dipengaruhi oleh naik turunnya perekonomian.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Potensi Keuntungan atas penjualan obligasi (capital gain)
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Selain penghasilan berupa kupon, pemegang obligasi dapat
                  memperoleh keuntungan ( capital gain) dengan menjual
                  obligasinya dengan harga lebih tinggi dibandingkan dengan
                  harga belinya.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faqDiv">
        <div className="faqText">
          <div className="monsterratExtraBold f2r mx-auto  mw90768 grey textCenter blue-text-1 f2r">
            Risiko-risiko dalam berinvestasi pada obligasi
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Risiko Tingkat Suku Bunga (Interest Rate Risk)
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Pergerakan harga obligasi sangat ditentukan pergerakan tingkat
                  suku bunga. Pergerakan harga obligasi berbanding terbalik
                  dengan tingkat suku bunga. Jika suku bunga naik maka harga
                  obligasi akan turun, sebaliknya jika suku bunga turun maka
                  harga obligasi akan naik.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Risiko gagal bayar( Default Risk)
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Adalah risiko penerbit tidak dapat membayar kewaiiban
                  (default) dalam hal ini tidak dapat membayar kupon obligasi
                  dan atau tidak dapat mengembalikan pokok obligasi. Jenis
                  risiko ini lebih dijumpai pada obligasi korporasi, walaupun
                  jarang terjadi. Sementara itu obligasi pemerintah (negara)
                  pembayaran kupon dan pokoknya 100% dijamin oleh negara dengan
                  Undang Undang.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Risiko Pasar (Market risk)
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  Risiko ini terkait pasar yang mirip dengan tingkat risiko suku
                  bunga, dengan perbedaan risiko pasar berkaitan dengan kerugian
                  atau capital loss karena faktor-faktor seperti perubahan
                  tingkat suku bunga, situasi politik yang bergejolak, serta
                  perubahan ekonomi negara Investor berisiko mengalami capital
                  loss dari obligasi ketika menjual kembali produk tersebut di
                  harga yang lebih rendah pada pasar sekunder dibanding harga
                  belinya. Obligasi dengan jatuh tempo lebih panjang misalnya 10
                  tahun akan memiliki risiko pasar yang lebih besar dibandingkan
                  dengan yang jatuh tempo lebih pendek, misalnya dalam 2 tahun.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="faqDiv">
        <div className="faqText">
          <div className="monsterratExtraBold f2r mx-auto  mw90768 grey textCenter blue-text-1 f2r">
            Sukuk dan SRBI
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper faqWrapper-long">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Sukuk
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  <p>
                    Sukuk atau dikenal sebagai obligasi syariah adalah surat
                    berharga yang merupakan bukti kepemilikan suatu aset pada
                    jangka waktu tertentu, berdasarkan prinsip syariah yang
                    dikeluarkan emiten, yang mewajibkan emiten untuk membayar
                    pendapatan kepada pemegang sukuk berupa bagi hasil serta
                    membayar kembali dana sukuk pada saat jatuh tempo.
                  </p>

                  <h6 class="monsterratSemiBold">
                    Perbedaan sukuk dan obligasi adalah :
                  </h6>
                  <ul>
                    <li>
                      Obligasi adalah surat hutang, sedangkan sukuk adalah bukti
                      kepemilikan atas suatu aset.
                    </li>
                    <li>
                      Obligasi membayar pendapatan dalam bentuk bunga, sedangkan
                      sukuk membayar pendapatan dalam bentuk bagi hasil.
                    </li>
                  </ul>

                  <h6 class="monsterratSemiBold">Jenis-jenis Sukuk</h6>
                  <h6 class="monsterratSemiBold">
                    Berdasarkan Pihak yang Menerbitkan, pembagian jenis sukuk
                    adalah sukuk negara dan sukuk korporasi.
                  </h6>
                  <ol>
                    <li>
                      <x class="monsterratSemiBold">
                        Surat Berharga Syariah Negara (SBSN)
                      </x>
                      <br />
                      <x style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        Surat Berharga Syariah Negara atau SBSN disebut juga
                        sukuk
                      </x>{" "}
                      negara, yaitu surat berharga yang dikeluarkan oleh negara
                      sebagai bukti atas bagian kepemilikan aset negara.
                    </li>
                    <li>
                      <x class="monsterratSemiBold">Sukuk Korporasi</x>
                      <br />
                      <x style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        Sukuk yang diterbitkan oleh pemilik obligasi syariah
                        dalam
                      </x>{" "}
                      suatu perusahaan.
                    </li>
                  </ol>

                  <h6 class="monsterratSemiBold">
                    Berdasarkan akad syariah, ada 2 jenis sukuk yang umum
                    dikenal :
                  </h6>
                  <ol>
                    <li>
                      <x class="monsterratSemiBold">Sukuk Mudharabah</x>
                      <br />
                      <x style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        adalah sukuk dengan perjanjian atau akad mudharabah.
                        Dalam
                      </x>{" "}
                      hal ini ada pihak pemberi modal (rab al-maal) dan
                      pengelola modal (mudharib). Pembagian keuntungannya
                      tergantung dari perbandingan yang telah disepakati.
                      Sementara kerugian akan ditanggung sepenuhnya oleh pemilik
                      modal.
                    </li>
                    <li>
                      <x class="monsterratSemiBold">Sukuk Ijarah</x>
                      <br />
                      <x style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        adalah sukuk dengan akad memindahkan hak penggunaan atas
                      </x>{" "}
                      barang dan jasa, tanpa ikut memindahkan kepemilikan. Akad
                      ijarah dalam sukuk adalah berupa sewa. Jadi hak
                      kepemilikan tetap pada orang yang sama.
                    </li>
                  </ol>

                  <h6 class="monsterratSemiBold">
                    Keuntungan dalam Berinvestasi pada Sukuk
                  </h6>
                  <ul>
                    <li>
                      Arus kas pendapatan periodik yang dapat diprediksi, berupa
                      imbalan/kupon dengan jumlah tetap seperti pada obligasi
                      konvensional, namun melalui pembagian profit halal sesuai
                      dengan prinsip syariah.
                    </li>
                    <li>
                      Memiliki risiko default yang lebih rendah dibanding
                      obligasi konvensional, karena sukuk didukung oleh aset
                      nyata, tidak hanya berdasarkan kredit penerbit.
                    </li>
                    <li>
                      Kepatuhan pada prinsip syariah dengan menghindari unsur
                      riba (bunga), spekulasi yang berlebihan, dan investasi
                      dalam bisnis yang haram.
                    </li>
                    <li>
                      Potensi memperoleh capital gain jika sukuk dijual di harga
                      yang lebih tinggi daripada harga beli dengan
                      memperhitungkan biaya transaksi.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faqContainer">
          <div className="faqWrapper faqWrapper-long">
            <div className="faqTitle px-4">
              <div className="">
                <div
                  className=" monsterratSemiBold blue-text-1 faqQuestion"
                  style={{}}
                >
                  Sekuritas Rupiah Bank Indonesia
                </div>
              </div>
              <button className="faqIndicator">
                <span></span>
                <span></span>
              </button>
            </div>
            <div className="faqAnswerContainer">
              <div className="faqAnswer monsterratMedium  px-4">
                <div
                  class="w100 pt-4"
                  style={{
                    borderTop: "1px solid black",
                  }}
                >
                  <div
                    class=" monsterratRegular  text-align-justify"
                    style={{ textAlign: "justify" }}
                  >
                    <p>
                      Sekuritas Rupiah Bank Indonesia (SRBI) adalah surat
                      berharga dalam mata uang rupiah yang diterbitkan oleh BI
                      sebagai pengakuan utang berjangka waktu pendek dengan
                      menggunakan underlying asset berupa surat berharga negara
                      (SBN) yang dimiliki oleh BI.
                    </p>

                    <h6 class="monsterratSemiBold">Karakteristik SRBI</h6>
                    <ul>
                      <li>Menggunakan underlying asset berupa SBN</li>
                      <li>Berjangka waktu 1 minggu sampai dengan 12 bulan</li>
                      <li>
                        Diterbitkan tanpa warkat dan diperdagangkan dengan
                        sistem diskonto
                      </li>
                      <li>Dapat dipindahtangankan</li>
                      <li>
                        Dapat dimiliki oleh penduduk dan bukan penduduk di pasar
                        sekunder
                      </li>
                    </ul>

                    <p>
                      Berbeda dengan obligasi dan sukuk, SRBI tidak memberikan
                      pendapatan periodik hingga jatuh tempo. Investor membeli
                      SRBI dengan harga diskon dan menerima pengembalian dana
                      berupa pokok SRBI pada saat jatuh tempo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="w100  bg-biru-muda  ">
        <div
          class="mt-5 rounded-corner w85 w951200 mx-auto"
          style={{ padding: "4rem 0rem 4rem 0rem" }}
        >
          <div
            class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
          >
            Keuntungan berinvestasi pada obligasi
          </div>
          <div
            class="w100 block768"
            style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
          >
            {" "}
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Obligasi dapat dijual sebelum jatuh tempo
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Karena diperdagangkan di pasar sekunder maka obligasi dapat
                  dijual sebelum jatuh tempo. Khususnya Obligasi Negara FR yang
                  sangat likuid oleh karena itu dapat dijual kapan saja tanpa
                  harus tunggu jatuh tempo.
                </div>
              </div>{" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Memberikan pendapatan tetap (fixed income) berupa kupon atau
                  bunga.
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Kupon atau bunga dibayarkan secara regular sampai dengan jatuh
                  tempo dan ditetapkan dalam persentase dari nilai nominal dan
                  merupakan passive income bagi pemegang obligasi. Perhitungan
                  kupon dilakukan secara akumulasi setiap hari dari saat
                  obligasi dibeli hingga jatuh tempo. Jika obligasi dijual
                  sebelum jatuh tempo, investor sebagai penjual tetap mendapat
                  pembayaran kupon sesuai dengan lamanya waktu obligasi
                  dimiliki. Tingkat bunga atau kupon obligasi biasanya lebih
                  tinggi dari tingkat bunga yang ditawarkan deposito perbankan.
                </div>
              </div>{" "}
            </div>
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Imbal hasil (yield) yang sudah tetap
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Investor bisa mengunci imbal hasil (yield) yang akan diterima
                  sejak obligasi dibeli hingga jatuh tempo (yield-to-maturity).
                  Imbal hasil obligasi biasanya di atas suku bunga deposito dan
                  tidak dipengaruhi oleh naik turunnya perekonomian.
                </div>
              </div>{" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid #0071BF",
                    marginBottom: "0.75rem",
                  }}
                >
                  Potensi Keuntungan atas penjualan obligasi (capital gain)
                </div>
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Selain penghasilan berupa kupon, pemegang obligasi dapat
                  memperoleh keuntungan ( capital gain) dengan menjual
                  obligasinya dengan harga lebih tinggi dibandingkan dengan
                  harga belinya.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div class="w100    ">
        <div
          class="mt-5 rounded-corner w85 w951200 mx-auto"
          style={{ padding: "2.8rem 0rem 4rem 0rem" }}
        >
          <div
            class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
          >
            Risiko-risiko dalam berinvestasi pada obligasi
          </div>
          <div
            class="w100 block768"
            style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
          >
            {" "}
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid black",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "0.75rem",
                  }}
                >
                  Risiko Tingkat Suku Bunga (Interest Rate Risk)
                </div>
                <div
                  class=" monsterratRegular  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Pergerakan harga obligasi sangat ditentukan pergerakan tingkat
                  suku bunga. Pergerakan harga obligasi berbanding terbalik
                  dengan tingkat suku bunga. Jika suku bunga naik maka harga
                  obligasi akan turun, sebaliknya jika suku bunga turun maka
                  harga obligasi akan naik.
                </div>
              </div>{" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid black",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "0.75rem",
                  }}
                >
                  Risiko gagal bayar( Default Risk)
                </div>
                <div
                  class=" monsterratRegular  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Adalah risiko penerbit tidak dapat membayar kewaiiban
                  (default) dalam hal ini tidak dapat membayar kupon obligasi
                  dan atau tidak dapat mengembalikan pokok obligasi. Jenis
                  risiko ini lebih dijumpai pada obligasi korporasi, walaupun
                  jarang terjadi. Sementara itu obligasi pemerintah (negara)
                  pembayaran kupon dan pokoknya 100% dijamin oleh negara dengan
                  Undang Undang.
                </div>
              </div>{" "}
            </div>
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid black",
                  height: "fit-content",
                }}
              >
                <div
                  class="  pb-2  blue-text-1 monsterratSemiBold f1r"
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "0.75rem",
                  }}
                >
                  Risiko Pasar (Market risk)
                </div>
                <div
                  class=" monsterratRegular  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  Risiko ini terkait pasar yang mirip dengan tingkat risiko suku
                  bunga, dengan perbedaan risiko pasar berkaitan dengan kerugian
                  atau capital loss karena faktor-faktor seperti perubahan
                  tingkat suku bunga, situasi politik yang bergejolak, serta
                  perubahan ekonomi negara Investor berisiko mengalami capital
                  loss dari obligasi ketika menjual kembali produk tersebut di
                  harga yang lebih rendah pada pasar sekunder dibanding harga
                  belinya. Obligasi dengan jatuh tempo lebih panjang misalnya 10
                  tahun akan memiliki risiko pasar yang lebih besar dibandingkan
                  dengan yang jatuh tempo lebih pendek, misalnya dalam 2 tahun.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w100  bg-biru-muda  ">
        <div
          class="mt-5 rounded-corner w85 w951200 mx-auto"
          style={{ padding: "4rem 0rem 4rem 0rem" }}
        >
          <div
            class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
          >
            Apakah Sukuk?
          </div>
          <div class="w100 block768">
            <div class="w100">
              {" "}
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  <p>
                    Sukuk atau dikenal sebagai obligasi syariah adalah surat
                    berharga yang merupakan bukti kepemilikan suatu aset pada
                    jangka waktu tertentu, berdasarkan prinsip syariah yang
                    dikeluarkan emiten, yang mewajibkan emiten untuk membayar
                    pendapatan kepada pemegang sukuk berupa bagi hasil serta
                    membayar kembali dana sukuk pada saat jatuh tempo.
                  </p>

                  <h4 class="monsterratSemiBold">
                    Perbedaan sukuk dan obligasi adalah :
                  </h4>
                  <ul>
                    <li>
                      Obligasi adalah surat hutang, sedangkan sukuk adalah bukti
                      kepemilikan atas suatu aset.
                    </li>
                    <li>
                      Obligasi membayar pendapatan dalam bentuk bunga, sedangkan
                      sukuk membayar pendapatan dalam bentuk bagi hasil.
                    </li>
                  </ul>

                  <h4 class="monsterratSemiBold">Jenis-jenis Sukuk</h4>
                  <h6 class="monsterratSemiBold">
                    Berdasarkan Pihak yang Menerbitkan, pembagian jenis sukuk
                    adalah sukuk negara dan sukuk korporasi.
                  </h6>
                  <ol>
                    <li>
                      <span class="monsterratSemiBold">
                        Surat Berharga Syariah Negara (SBSN)
                      </span>
                      <br />
                      <span style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        Surat Berharga Syariah Negara atau SBSN disebut juga
                        sukuk
                      </span>{" "}
                      negara, yaitu surat berharga yang dikeluarkan oleh negara
                      sebagai bukti atas bagian kepemilikan aset negara.
                    </li>
                    <li>
                      <span class="monsterratSemiBold">Sukuk Korporasi</span>
                      <br />
                      <span style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        Sukuk yang diterbitkan oleh pemilik obligasi syariah
                        dalam
                      </span>{" "}
                      suatu perusahaan.
                    </li>
                  </ol>

                  <h6 class="monsterratSemiBold">
                    Berdasarkan akad syariah, ada 2 jenis sukuk yang umum
                    dikenal :
                  </h6>
                  <ol>
                    <li>
                      <span class="monsterratSemiBold">Sukuk Mudharabah</span>
                      <br />
                      <span style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        adalah sukuk dengan perjanjian atau akad mudharabah.
                        Dalam
                      </span>{" "}
                      hal ini ada pihak pemberi modal (rab al-maal) dan
                      pengelola modal (mudharib). Pembagian keuntungannya
                      tergantung dari perbandingan yang telah disepakati.
                      Sementara kerugian akan ditanggung sepenuhnya oleh pemilik
                      modal.
                    </li>
                    <li>
                      <span class="monsterratSemiBold">Sukuk Ijarah</span>
                      <br />
                      <span style={{ fontSize: 14 }} class="monsterratRegular">
                        {" "}
                        adalah sukuk dengan akad memindahkan hak penggunaan atas
                      </span>{" "}
                      barang dan jasa, tanpa ikut memindahkan kepemilikan. Akad
                      ijarah dalam sukuk adalah berupa sewa. Jadi hak
                      kepemilikan tetap pada orang yang sama.
                    </li>
                  </ol>

                  <h4 class="monsterratSemiBold">
                    Keuntungan dalam Berinvestasi pada Sukuk
                  </h4>
                  <ul>
                    <li>
                      Arus kas pendapatan periodik yang dapat diprediksi, berupa
                      imbalan/kupon dengan jumlah tetap seperti pada obligasi
                      konvensional, namun melalui pembagian profit halal sesuai
                      dengan prinsip syariah.
                    </li>
                    <li>
                      Memiliki risiko default yang lebih rendah dibanding
                      obligasi konvensional, karena sukuk didukung oleh aset
                      nyata, tidak hanya berdasarkan kredit penerbit.
                    </li>
                    <li>
                      Kepatuhan pada prinsip syariah dengan menghindari unsur
                      riba (bunga), spekulasi yang berlebihan, dan investasi
                      dalam bisnis yang haram.
                    </li>
                    <li>
                      Potensi memperoleh capital gain jika sukuk dijual di harga
                      yang lebih tinggi daripada harga beli dengan
                      memperhitungkan biaya transaksi.
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class={`monsterratBold  f2r mb-5 blue-text-1  mw90768 mx-auto text-center`}
              >
                Sekuritas Rupiah Bank Indonesia
              </div>
              <div
                class=" px-4 py-3  mx-4"
                style={{
                  borderRadius: 20,
                  marginBottom: "2.4rem",
                  border: "1px solid #0071BF",
                  height: "fit-content",
                }}
              >
                <div
                  class=" monsterratRegular blue-text-1  text-align-justify"
                  style={{ textAlign: "justify" }}
                >
                  <p>
                    Sekuritas Rupiah Bank Indonesia (SRBI) adalah surat berharga
                    dalam mata uang rupiah yang diterbitkan oleh BI sebagai
                    pengakuan utang berjangka waktu pendek dengan menggunakan
                    underlying asset berupa surat berharga negara (SBN) yang
                    dimiliki oleh BI.
                  </p>

                  <h4 class="monsterratSemiBold">Karakteristik SRBI</h4>
                  <ul>
                    <li>Menggunakan underlying asset berupa SBN</li>
                    <li>Berjangka waktu 1 minggu sampai dengan 12 bulan</li>
                    <li>
                      Diterbitkan tanpa warkat dan diperdagangkan dengan sistem
                      diskonto
                    </li>
                    <li>Dapat dipindahtangankan</li>
                    <li>
                      Dapat dimiliki oleh penduduk dan bukan penduduk di pasar
                      sekunder
                    </li>
                  </ul>

                  <p>
                    Berbeda dengan obligasi dan sukuk, SRBI tidak memberikan
                    pendapatan periodik hingga jatuh tempo. Investor membeli
                    SRBI dengan harga diskon dan menerima pengembalian dana
                    berupa pokok SRBI pada saat jatuh tempo.
                  </p>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
