import logo from "../../logo.svg";
import "./style.css";
import idx_islamic_chg from "../../assets/idx_islamic_chg.jpg";
import merdeka78 from "../../assets/merdeka78.jpg";
import CIMB from "../../assets/CIMB.jpg";
import koins from "../../assets/koins.png";
import loading from "../../assets/loading.gif";
import marginTimeline from "../../assets/marginTimeline.jfif";
import kisiMobile from "../../assets/kisiMobile.png";
import letter from "../../assets/letter.png";
import mail from "../../assets/email.png";
import nikNpwp from "../../assets/nikNpwp.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import promoMarginMain from "../../assets/promoMarginMain.jpg";
import promoEtfMain from "../../assets/promoEtfMain.jpg";
import promoCIMBSyariah from "../../assets/promoCIMBSyariah.png";
import promoCIMBSyariahMobile from "../../assets/promoCIMBSyariahMobile.png";
import etfFee from "../../assets/etfFee.png";
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import BlogMenu from "../../components/BlogMenu";
import marginCheck from "../../assets/marginCheck.png";
import regAccInterest from "../../assets/regAccInterest.png";
import marginAccInterest from "../../assets/marginAccInterest.png";
import { getPromos } from "../../store/action";
import promoReksadana23 from "../../assets/promoReksadana23.jpg";
import { NotFound } from "../NotFound";
import { host, storageLocation } from "../../config/server";
import { server } from "../../config/server";
import payday_2 from "../../assets/payday_2.png";
export default function Announcement() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const [promoListFromContent, setPromoListFromContent] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [content, setContent] = useState();
  const params = useLocation();
  const currentPath = params.pathname;
  const [imageLoaded, setImageLoaded] = useState(false);
  const getContent = () => {
    fetch(server + `/api/v1/web/promos?slug=${page}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
        access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (result) => {
        console.log(result, "hasil");
        if (result.data.length > 0) {
          setContent(result.data[0]);
        }
        setIsDataFetched(true);
        // console.log(result);
        // console.log(result);
        // await dispatch(setSelectedData(result));
        // const structuredData = await tableDataStructurer(
        //   result,
        //   selectDetails
        // );
        // const { dataToSet, columnToSet } = structuredData;
        // // console.log(dataToSet, "loped", columnToSet);
        // await dispatch(setTableColumns(columnToSet));
        // await dispatch(setTableData(dataToSet));
        // resolve(result);
      })
      .catch((err) => {
        setIsDataFetched(true);
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("asw", isDataFetched, promoListFromContent);
    window.scrollTo(0, 0);
    // getContent();
  }, [page]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const PromoSwitcher = () => {
    switch (page) {
      // case "promo_etf":
      //   return PromoEtf();
      //   break;
      // case "promo_margin":
      //   return PromoMargin();
      //   break;
      // case "cimb_syariah":
      //   return PromoCIMBSyariah();
      //   break;
      // case "idx_islamic_chg":
      //   return PromoIdxIslamic();
      //   break;
      // case "event_merdeka_78":
      //   return PromoMerdeka78();
      //   break;
      // case "event_payday":
      //   return PromoPayday();
      // case "event_payday_november23":
      //   return PromoPayday2();
      //   break;
      default:
        return PromoContent();
        break;
    }
  };

  const PromoContent = () => {
    console.log(isDataFetched, "huk");

    if (content) {
      return (
        <div class={`promoPageContainer ${imageLoaded ? "o1" : "o0"}`}>
          <div class="promoPageWrapper">
            <div class="CIMBPageContainer">
              <div class="CIMBPageWrapper">
                <div class="CIMBTextWrapper py-5">
                  <img
                    onLoad={() => {
                      setImageLoaded(true);
                    }}
                    class="w100 mb-5"
                    src={nikNpwp}
                  ></img>
                  {}
                  {/* <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
                    {content.title}
                    <p></p>
                  </div>
          
                  <div
                    class="etfContent f1r monsterratMedium grey"
                    dangerouslySetInnerHTML={{ __html: content.content }}
                  ></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (isDataFetched) {
        return <NotFound />;
      } else {
        return (
          <div class="w100 text-center center-vertical" style={{ height: 700 }}>
            {/* {JSON.stringify(isDataFetched)} */}
            <img class="mx-auto center-vertical" src={loading}></img>
          </div>
        );
      }
    }

    // return (
    //   <div class="promoPageContainer">

    //     <div class="promoPageWrapper">
    //       <div class="CIMBPageContainer">
    //         <div class="CIMBPageWrapper">
    //           <div class="CIMBTextWrapper py-5">
    //             <img class="w100 mb-5" src={merdeka78}></img>
    //             <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
    //               GIVEAWAY KISI MERAYAKAN KEMERDEKAAN 78<br></br>
    //               <p></p>
    //             </div>
    //             {page} {currentPath}
    //             {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
    //       Syarat & Ketentuan menjadi nasabah ETF
    //       </div> */}
    //             <div class="etfContent f1r monsterratMedium grey">
    //               <p>{/* <b>Syarat dan Ketentuan:</b> */}</p>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 Kami dengan bangga mengumumkan bahwa dalam rangka
    //                 Memperingati Hari Ulang Tahun (HUT) Kemerdekaan Republik
    //                 Indonesia (RI) pada tanggal 17 Agustus 2023 yang ke 78
    //                 tahun, perusahaan kami akan menggelar Program Giveaway
    //                 Kemerdekaan yang menarik dan menghibur! Ini adalah
    //                 kesempatan bagi kami untuk berbagi kegembiraan dan semangat
    //                 kemerdekaan bersama Anda.
    //               </div>
    //               <div class="f15r blue-text-1 monsterratMedium my-3 ">
    //                 Deskripsi Program
    //               </div>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 Share momen Anda seputar kemerdekaan di feed instagram
    //                 pribadi Anda sertakan caption dan jangan lupa tag akun
    //                 Instagram{" "}
    //                 <span
    //                   style={{ cursor: "pointer" }}
    //                   class="blue-text-1"
    //                   onClick={() => {
    //                     openInNewTab(
    //                       "https://www.instagram.com/koreainvestment.id/"
    //                     );
    //                   }}
    //                 >
    //                   @koreainvestment.id{" "}
    //                 </span>{" "}
    //                 setelah itu Tag 3 temen Anda sertakan hashtag{" "}
    //                 <span class="blue-text-1 monsterratBold">
    //                   #GiveawayKISI #merdeka78 #GIVEAWAYKISIKEMERDEKAAN78
    //                 </span>
    //               </div>
    //               <div class="f15r blue-text-1 monsterratMedium my-3 ">
    //                 Periode Program
    //               </div>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 <ul>
    //                   <li>Program dimulai pada tanggal 17-25 Agustus 2023</li>
    //                   <li>Pemilihan pemenang pada tanggal 28 Agustus 2023</li>
    //                   <li>
    //                     Pengumuman pemenang di Instagram pada tanggal 29 Agustus
    //                     2023
    //                   </li>
    //                   <li>Distribusi 30 Agustus -5 September 2023</li>
    //                 </ul>
    //               </div>
    //               <div class="f15r blue-text-1 monsterratMedium my-3 ">
    //                 Syarat & Ketentuan
    //               </div>
    //               Cara Partisipasi Giveaway Kemerdekaan 78 :
    //               <ul>
    //                 <li>
    //                   Wajib Nasabah KOINS (jika non-nasabah diharuskan mendaftar
    //                   terlebih dahulu)
    //                 </li>
    //                 <li>
    //                   Pemilihan pemenang akan diumumkan di Instagram{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                   pada tanggal 29 Agustus 2023
    //                 </li>
    //                 <li>
    //                   Wajib Follow akun Instagram{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                 </li>
    //                 <li>
    //                   Like postingan Feed Giveaway Kemerdekaan Instagram{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                 </li>
    //                 <li>Pastikan akun Instagram Anda tidak di private</li>
    //                 <li>
    //                   Setelah itu jangan lupa untuk comment postingan Feed
    //                   Giveaway Kemerdekaan di kolom komentar{" "}
    //                   <span
    //                     style={{ cursor: "pointer" }}
    //                     class="blue-text-1"
    //                     onClick={() => {
    //                       openInNewTab(
    //                         "https://www.instagram.com/koreainvestment.id/"
    //                       );
    //                     }}
    //                   >
    //                     @koreainvestment.id{" "}
    //                   </span>{" "}
    //                   dengan kata “DONE” setelah melakukan tugas yang diberikan
    //                 </li>
    //                 <li>
    //                   100 momen terbaik akan diberikan hadiah Voucher Gopay
    //                   sebesar Rp 78.000 dari KISI
    //                 </li>

    //                 <li>
    //                   KISI berhak secara sepihak membatalkan kemenangan peserta,
    //                   jika terdapat indikasi kecurangan, penipuan, dan
    //                   ketidakpatuhan yang dapat merugikan KISI dan peserta
    //                   lainnya
    //                 </li>
    //                 <li>
    //                   Pemenang wajib memberikan bukti screenshot code client,
    //                   E-mail & nomor telepon yang terdaftar di KISI
    //                 </li>
    //               </ul>
    //               <br></br>
    //               <div class="f1r monsterratMedium my-3 ">
    //                 Hormat kami,<br></br>
    //                 PT Korea Investment And Sekuritas Indonesia
    //               </div>
    //               <p></p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  };
  return (
    <>
      <div class={`promoPageContainer ${imageLoaded ? "o1" : "o0"}`}>
        <div class="promoPageWrapper">
          <div class="CIMBPageContainer">
            <div class="CIMBPageWrapper">
              <div class="CIMBTextWrapper pb-5 text-center">
                <img
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  class="w100 mb-5"
                  src={nikNpwp}
                  style={{ width: 500 }}
                ></img>
                {}
                {/* <div class="f25r  monsterratExtraBold my-5 text-center blue-text-1 mb-5 pb-5">
          
                  <p></p>
                </div> */}
                {/* <div class="my-5 f25r nowrap blue-text-1 monsterratExtraBold text-center">
            Syarat & Ketentuan menjadi nasabah ETF
            </div> */}
                <div
                  class="etfContent f1r monsterratMedium"
                  style={{ textAlign: "left" }}
                >
                  <p>Kepada Yth.</p>

                  <p>Bapak/Ibu Nasabah</p>

                  <p class="monsterratSemiBold">PT Korea Investment And Sekuritas Indonesia</p>
                  <p class="monsterratSemiBold">
                    PT Korea Investment And Sekuritas Indonesia
                  </p>

                  <p>Dengan hormat,</p>

                  <p>
                    Sehubungan dengan Perubahan atas Peraturan Menteri Keuangan
                    Republik Indonesia Nomor 112/PMK.03/2022 tentang Nomor Pokok
                    Wajib Pajak Bagi Wajib Pajak Orang Pribadi, Wajib Pajak
                    Badan, dan Wajib Pajak Instansi Pemerintah yang semula
                    berlaku pada tanggal 1 Juli 2024, maka sesuai dengan
                    Peraturan Menteri Keuangan Republik Indonesia Nomor 136
                    tahun 2023 tentang Penyesuaian Digit NPWP dimaksud, <span class="monsterratSemiBold">akan
                    diberlakukan pada tanggal 23 November 2024.
                        </span>
                    tahun 2023 tentang Penyesuaian Digit NPWP dimaksud,{" "}
                    <span class="monsterratSemiBold">
                      akan diberlakukan pada tanggal 23 November 2024.
                    </span>
                  </p>

                  <p class="monsterratSemiBold">PEMBERITAHUAN PENTING:</p>

                  <p>
                    Sesuai Peraturan Menteri Keuangan No. 136/2023, maka kami PT
                    Korea Investment And Sekuritas Indonesia akan memberlakukan
                    kepada <span class="monsterratSemiBold">nasabah baru yang melakukan Pembukaan Rekening </span>
                    sebagai berikut : <br></br>
                    - Nomor Induk Kependudukan (NIK) akan
                    secara otomatis menjadi Nomor Pokok Wajib Pajak (NPWP)
                    <br></br>
                    -
                    Sistem perpajakan akan menggunakan NIK sebagai identitas
                    tunggal
                    kepada{" "}
                    <span class="monsterratSemiBold">
                      nasabah baru yang melakukan Pembukaan Rekening{" "}
                    </span>
                    sebagai berikut : <br></br>- Nomor Induk Kependudukan (NIK)
                    akan secara otomatis menjadi Nomor Pokok Wajib Pajak (NPWP)
                    <br></br>- Sistem perpajakan akan menggunakan NIK sebagai
                    identitas tunggal
                  </p>

                  <p>
                    Atas perhatian dan kerja sama Bapak/Ibu, kami mengucapkan
                    terima kasih.
                  </p>

                  <p></p>

                  <p>Hormat kami,</p>

                  <p class="monsterratSemiBold">PT Korea Investment And Sekuritas Indonesia</p>
                  <p class="monsterratSemiBold">
                    PT Korea Investment And Sekuritas Indonesia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
