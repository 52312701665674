import logo from "../../../logo.svg";
import "./style.css";
import PageLocation from "../../../components/PageLocation";
import obligasi from "../../../assets/obligasi.png";
import reksadanaSaham from "../../../assets/reksadanaSaham.png";
import reksadanaBanner from "../../../assets/reksadanaBanner.png";
import reksadanaTerproteksi from "../../../assets/reksadanaTerproteksi.png";
import reksadanaPendapatanTetap from "../../../assets/reksadanaPendapatanTetap.png";
import reksadanaPasarUang from "../../../assets/reksadanaPasarUang.png";
import reksadanaCampuran from "../../../assets/reksadanaCampuran.png";
import AM_CAPITAL from "../../../assets/AM_CAPITAL.png";
import AM_KISI from "../../../assets/AM_KISI.png";
import AM_STAR from "../../../assets/AM_STAR.png";
import AM_PINNACLE from "../../../assets/AM_PINNACLE.png";
import AM_AVRIST from "../../../assets/AM_AVRIST.png";
import AM_INSIGHT from "../../../assets/AM_INSIGHT.png";
import FollowSocmed from "../../../components/FollowSocmed";
import { Swiper, SwiperSlide } from "swiper/react";

import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import { useNavigate } from "react-router-dom";
import reksadana from "../../../assets/reksadana.jpg";
import reksadanaMobile from "../../../assets/reksadanaMobile.jpg";
import arrow from "../../../assets/arrow.png";
import { Autoplay } from "swiper/modules";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import { setActiveMenu } from "../../../store/action";
import { useDispatch, useSelector } from "react-redux";
//
import reksadanaBg from "../../../assets/reksadanaBg.png";
//
import stepReksadana1 from "../../../assets/stepReksadana1.png";
import stepReksadana2 from "../../../assets/stepReksadana2.png";
import stepReksadana3 from "../../../assets/stepReksadana3.png";
import stepReksadana4 from "../../../assets/stepReksadana4.png";
import stepReksadana5 from "../../../assets/stepReksadana5.png";
import stepReksadana6 from "../../../assets/stepReksadana6.png";
export function Reksadana() {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState(<Swiper />);
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    // window.scrollTo(0, 0);
    // console.log(window.innerWidth);
    Aos.init({ duration: 2000 });
    dispatch(setActiveMenu("Produk"));
  }, []);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);
  const arrowHeight = () => {
    let appSpacer = document.getElementsByClassName("langkahSpacerApp");
    let spacerHeight = document.getElementById("slide_langkah_0").offsetHeight;
    for (let i = 0; i < appSpacer.length; i++) {
      const element = appSpacer[i];
      element.style.height = `${spacerHeight}px`;
    }
  };
  const steps = [
    {
      source: stepReksadana1,
      content: {
        date: "04.28.2020",
        desc: "Sign In Aplikasi KISI Mobile",
      },
    },
    {
      source: stepReksadana2,
      content: {
        date: "04.28.2020",
        desc: "Klik akses Menu Reksa Dana untuk ke KINDS",
      },
    },
    {
      source: stepReksadana3,
      content: {
        date: "04.28.2020",
        desc: "Lihat Semua Produk </br> Reksa Dana",
      },
    },
    {
      source: stepReksadana4,
      content: {
        date: "04.27.2020",
        desc: "Pilih Produk Reksa Dana yang diinginkan lalu klik Beli",
      },
    },
    {
      source: stepReksadana5,
      content: {
        date: "04.23.2020",
        desc: "Masukkan nominal yang diinginkan lalu baca serta setujui syarat dan ketentuan yang berlaku",
      },
    },
    {
      source: stepReksadana6,
      content: {
        date: "04.21.2020",
        desc: "Selesai.</br>lalu cek proses orderan kamu pada menu transaksi",
      },
    },
  ];
  const langkahPrev = (e) => {
    swiper.slidePrev();
  };
  const langkahNext = (e) => {
    swiper.slideNext();
  };
  const slideChangeLangkah = (e) => {
    setSlideIndex(e.realIndex);
  };

  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      {/* <PageLocation left="Home" right="Reksa Dana" subPath="Reksadana" /> */}
      <div class="reksadanaContainer">
        <div
          class="imageDivWrapper reksadanaImgLarge"
          // style={{ backgroundImage: `url(${reksadanaBg})` }}
        >
          {/* <img   onLoad={() => {
                setImageLoaded(true);
              }}src={reksadanaBg} class="reksadanaBg"></img> */}
          {/* {window.innerWidth > 768 ? (
            <img
              onLoad={() => {
                setImageLoaded(true);
              }}
              src={reksadana}
              alt="banner image"
              class="imageZ1"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          ) : (
            <img
              onLoad={() => {
                setImageLoaded(true);
              }}
              src={reksadanaMobile}
              alt="banner image"
              class="imageZ1"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          )} */}
          {/* <img
        onLoad={()=>{setImageLoaded(true)}}
          src={reksadana}
          alt="banner image"
          class="imageZ1"
          data-aos="fade-up"
          data-aos-duration="2000"
        /> */}
          <div class="insideWrapperAbsolute reksadanaInnerImg centerAbsolute768">
            <div class="downloadButtonGroup">
              <div class=" etfDownloadButtonContainer">
                <button
                  class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                  onClick={() => navigate("/kisimobile")}
                >
                  DOWNLOAD SEKARANG
                </button>

                <div class="flex mobileMarketplaceContainer">
                  <div class="  flexStart playStore">
                    <img
                      class="mobileMarketplace"
                      src={playStore}
                      onClick={() =>
                        openInNewTab(
                          "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                        )
                      }
                    />
                  </div>
                  <div class="  flexEnd appStore">
                    <img
                      class="mobileMarketplace"
                      src={appStore}
                      onClick={() =>
                        openInNewTab(
                          "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="insideWrapperAbsolute reksadanaMascot">
            <img
              class="reksadanaMascotImg"
              data-aos="fade-up"
              data-aos-duration="3000"
              onLoad={() => {
                setImageLoaded(true);
              }}
              src={reksadanaBanner}
            ></img>
          </div>
          <div class="insideWrapperAbsolute reksadanaBannerText  centerAbsolute768">
            <div class="reksadanaBannerTitle monsterratExtraBold  centerAbsolute768">
              Investasi Mudah <br></br>Mulai dari
              <div class="mt-2 f5r" style={{ color: "#FBB03A" }}>
                10.000
              </div>
            </div>
            <div class="reksadanaBannerSubtitle centerAbsolute768 nowrap monsterratMedium grey f1r mt-4 mw90">
              Sudah bisa beli Reksa Dana dan raih tujuan keuanganmu dengan
              berinvestasi<br></br> Reksa Dana Bersama <b>KINDS</b>, Investasi
              kekinian yang terjangkau dan mudah.
            </div>
          </div>
        </div>
        <div class="reksadanaUpperContainer">
          <div class="monsterratExtraBold  f275r mb-3 blue-text-1">
            Reksa Dana
          </div>
          {/* <div class="monsterratMedium mt-2 f15r blue-text-1">
          Fund by KISI Mobile
        </div> */}
          <div class="monsterratMedium reksadanaDescriptionTxt grey jenisAkunText mt-3 f1r w80">
            Reksa Dana adalah wadah instrument investasi yang menghimpun dana
            investor dan dikelola oleh manajer investasi yang nantinya akan
            dialokasikan ke portofolio efek.
          </div>
          {/* <div class="monsterratMedium grey jenisAkunText mt-2 f1r">
          Untuk dapat melakukan transaksi Reksa Dana silahkan download Aplikasi
          KISI Mobile
        </div> */}

          <div class="mt-5 bg-biru-muda reksadanaBlueContainer rounded-corner py-5">
            <div
              class={`monsterratExtraBold  f275r mb-3 blue-text-1 mt-3 mw90768 mx-auto ${
                window.innerWidth < 768 ? "text-center" : null
              }`}
            >
              Keunggulan Investasi Reksa Dana
            </div>
            <div class="reksadanaPointContainer reksadanaDescriptionTxt">
              {" "}
              <div class="mt-4 pt-3">
                <div class="blue-text-1 monsterratMedium f13r mb-1">
                  Investasi Lebih Terjangkau
                </div>
                <div class="grey monsterratMedium f1r">
                  Investasi di KINDS dapat dimulai dari Rp.10.000.
                </div>
              </div>
              <div class="mt-4 pt-3">
                <div class="blue-text-1 monsterratMedium f13r mb-1">
                  Investasi Lebih Likuid
                </div>
                <div class="grey monsterratMedium f1r">
                  Investasi di KINDS dapat dicairkan kapan saja tanpa ada
                  keterikatan jangka waktu tertentu.
                </div>
              </div>
              <div class="mt-4 pt-3">
                <div class="blue-text-1 monsterratMedium f13r mb-1">
                  Investasi Dikelola oleh Profesional
                </div>
                <div class="grey monsterratMedium f1r">
                  Reksa Dana akan dikelola secara berkala oleh Manajer Investasi
                  secara profesional.
                </div>
              </div>
              <div class="mt-4 pt-3">
                <div class="blue-text-1 monsterratMedium f13r mb-1">
                  Investasi Dengan Diversifikasi
                </div>
                <div class="grey monsterratMedium f1r">
                  Reksa Dana memiliki pilihan produk yang bermacam-macam dan
                  dapat disesuaikan dengan profil risiko masing-masing.
                </div>
              </div>
              <div class="mt-4 pt-3">
                <div class="blue-text-1 monsterratMedium f13r mb-1">
                  Investasi Tanpa Biaya Tambahan
                </div>
                <div class="grey monsterratMedium f1r">
                  Transaksi Reksa Dana di KINDS tidak dikenakan biaya transaksi
                  beli dan jual.
                </div>
              </div>
              <div class="mt-4 pt-3">
                <div class="blue-text-1 monsterratMedium f13r mb-1">
                  Investasi yang Aman dan Legal
                </div>
                <div class="grey monsterratMedium f1r">
                  Investasi Reksa Dana di KINDS aman dan legal karena sudah
                  berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK).
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="jenisReksadanaContainer jenisAkunText  f15r ">
          <div class="monsterratExtraBold  f275r my-5 blue-text-1 text-center">
            Jenis-Jenis Reksa Dana
          </div>
          <div class="reksadanaImgWrapper mt-5 pt-3">
            <div class="reksadanaImgContainer">
              <div class="reksadanaHover">
                <div class="reksadanaImgBorder">
                  <img class="reksadanaImg" src={reksadanaPasarUang} />
                </div>

                <div class="reksadanaImgDescription monsterratBold blue-text-1">
                  Reksa Dana Pasar Uang
                </div>
              </div>

              <div class="reksadanaDescriptionContainer">
                <div class="jago-syariah triangle"></div>
                <div class="reksadanaDescription py-3 px-3">
                  <div class="blue-text-1 f12r monsterratSemiBold">
                    Reksa Dana Pasar Uang
                  </div>
                  <div class="f1r grey monsterratMedium">
                    Reksa Dana yang dikelola 100% ke dalam instrumen pasar uang
                    (Obligasi yang periode jatuh temponya kurang dari 1 tahun,
                    deposito).
                  </div>
                </div>
              </div>
            </div>
            <div class="reksadanaImgContainer">
              <div class="reksadanaHover">
                <div class="reksadanaImgBorder">
                  <img class="reksadanaImg" src={reksadanaSaham} />
                </div>

                <div class="reksadanaImgDescription monsterratBold blue-text-1">
                  Reksa Dana Saham
                </div>
              </div>
              <div class="reksadanaDescriptionContainer">
                <div class="jago-syariah triangle"></div>
                <div class="reksadanaDescription py-3 px-3">
                  <div class="blue-text-1 f12r monsterratSemiBold">
                    Reksa Dana Pasar Uang
                  </div>
                  <div class="f1r grey monsterratMedium">
                    {" "}
                    Reksa Dana yang dikelola minimal 80% ke dalam saham.
                  </div>
                </div>
              </div>
            </div>
            <div class="reksadanaImgContainer">
              <div class="reksadanaHover">
                <div class="reksadanaImgBorder">
                  <img class="reksadanaImg" src={reksadanaPendapatanTetap} />
                </div>

                <div class="reksadanaImgDescription monsterratBold blue-text-1">
                  Reksa Dana Pendapatan Tetap
                </div>
              </div>
              <div class="reksadanaDescriptionContainer">
                <div class="jago-syariah triangle"></div>
                <div class="reksadanaDescription py-3 px-3">
                  <div class="blue-text-1 f12r monsterratSemiBold">
                    Reksa Dana Pendapatan Tetap
                  </div>
                  <div class="f1r grey monsterratMedium">
                    Reksa Dana yang dikelola minimal 80% ke dalam Obligasi
                    (Surat Utang) Negara/korporasi.
                  </div>
                </div>
              </div>
            </div>
            <div class="reksadanaImgContainer">
              <div class="reksadanaHover">
                <div class="reksadanaImgBorder">
                  <img class="reksadanaImg" src={reksadanaCampuran} />
                </div>

                <div class="reksadanaImgDescription monsterratBold blue-text-1">
                  Reksa Dana Campuran
                </div>
              </div>
              <div class="reksadanaDescriptionContainer">
                <div class="jago-syariah triangle"></div>
                <div class="reksadanaDescription py-3 px-3">
                  <div class="blue-text-1 f12r monsterratSemiBold">
                    Reksa Dana Campuran
                  </div>
                  <div class="f1r grey monsterratMedium">
                    Reksa Dana yang dikelola maksimal 79% ke dalam instrumen
                    pasar uang, obligasi, atau saham.
                  </div>
                </div>
              </div>
            </div>
            <div class="reksadanaImgContainer">
              <div class="reksadanaHover">
                <div class="reksadanaImgBorder">
                  <img class="reksadanaImg" src={reksadanaTerproteksi} />
                </div>

                <div class="reksadanaImgDescription monsterratBold blue-text-1">
                  Reksa Dana Terproteksi
                </div>
              </div>
              <div class="reksadanaDescriptionContainer">
                <div class="jago-syariah triangle"></div>
                <div class="reksadanaDescription py-3 px-3">
                  <div class="blue-text-1 f12r monsterratSemiBold">
                    Reksa Dana Terproteksi
                  </div>
                  <div class="f1r grey monsterratMedium">
                    Adalah jenis reksadana yang memberikan proteksi atas nilai
                    investasi awal apabila pemegang unitnya hold kepemilikannya
                    sampai tanggal jatuh tempo melalui mekanisme pengelolaan
                    portofolio. Secara periodik, produk ini juga membagikan
                    imbal hasil berupa dividen.
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="reksadanaImgContainer">
              <div class="reksadanaImgBorder">
                <img class="reksadanaImg" src={obligasi} />
              </div>
              <div class="reksadanaImgDescription monsterratBold blue-text-1">
                Obligasi
              </div>
            </div> */}
          </div>
        </div>
        <div class=" risikoReksadana mx-auto mt-5 pt-5 pb-5">
          <div class="monsterratExtraBold  risikoTitle text-center f275r mb-5 blue-text-1 mt-3">
            Risiko Investasi Reksa Dana
          </div>
          <div class="reksadanaPointContainer reksadanaDescriptionTxt">
            {" "}
            <div class="mt-4 pt-3">
              <div class="blue-text-1 monsterratMedium f13r mb-1">
                Risiko Penurunan Nilai Aktiva Bersih (NAB)
              </div>
              <div class="grey monsterratMedium f1r">
                Risiko yang terjadi Ketika berkurangnya nilai unit penyertaan
                akibat penurunan NAB reksa dana yang disebabkan oleh fluktuasi
                pasar itu sendiri (ekonomi, politik, dan lainnya).
              </div>
            </div>
            <div class="mt-4 pt-3">
              <div class="blue-text-1 monsterratMedium f13r mb-1">
                Risiko Likuiditas
              </div>
              <div class="grey monsterratMedium f1r">
                Risiko yang terjadi Ketika Manajer Investasi terlambat untuk
                melakukan pencairan dana ke Investor yang disebabkan oleh
                penjualan kembali secara serentak oleh Investor.
              </div>
            </div>
            <div class="mt-4 pt-3">
              <div class="blue-text-1 monsterratMedium f13r mb-1">
                Risiko Wanprestasi
              </div>
              <div class="grey monsterratMedium f1r">
                Risiko yang terjadi Ketika suatu penerbit surat berharga yang
                menjadi objek investasi reksa dana mengalami kegagalan dalam
                memenuhi kewajibannya.
              </div>
            </div>
            <div class="mt-4 pt-3">
              <div class="blue-text-1 monsterratMedium f13r mb-1">
                Risiko Perubahan Kondisi Politik, Ekonomi, dan Peraturan
              </div>
              <div class="grey monsterratMedium f1r">
                Risiko yang terjadi Ketika kinerja portofolio investasi reksa
                dana berubah dikarenakan perubahan kondisi politik, ekonomi
                serta perubahan pada peraturan dan kebijakan oleh pemerintah.
              </div>
            </div>
          </div>
        </div>
        <div className="appSliderContianer mb-5">
          {" "}
          <div className="mx-auto monsterratExtraBold mw80768 f2r grey textCenter  blue-text-1">
            Cara Membeli Reksa Dana
          </div>
          <div className="monsterratMedium mw70768 mx-auto text-center grey f12r mb-5 pb-4 langkahText">
            5 Langkah mudah membeli Reksa Dana
          </div>
          <div className="swiperContainer flex">
            <div className="appSpacerLeft  appSpacerHeight langkahSpacerApp center-vertical">
              <img
                className="sliderArrow  arrowLeft"
                onClick={(e) => langkahPrev(e)}
                src={arrow}
              ></img>
            </div>
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={false}
              slidesPerView={3}
              // loop={true}

              initialSlide={0}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              // autoplay={{
              //   delay: "5000",
              //   disableOnInteraction: false,
              //   pauseOnMouseEnter: true,
              // }}
              // allowSlidePrev={slideIndex3 == 1 ? false:true}
              modules={[Autoplay]}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                200: {
                  slidesPerView: 1,
                },
              }}
              pagination={true}
              className="swiperLangkah "
              onTransitionEnd={(e) => slideChangeLangkah(e)}
              // navigation={true}
              onSwiper={(swiper) => setSwiper(swiper)}
            >
              {steps.map((slide, i) => {
                return (
                  <SwiperSlide
                    key={i}
                    //  allowSlidePrev={false}
                  >
                    <div className="slideWrapper">
                      <div
                        className="slideContainerLangkah"
                        id={`slide_langkah_${i}`}
                      >
                        <img
                          onLoad={() => arrowHeight()}
                          className="slideLangkahImg"
                          onClick={() => {
                            if (slide.navigate) {
                              navigate(slide.navigate);
                            }
                          }}
                          src={slide.source}
                          alt=""
                        />
                      </div>
                      <div className="infos">
                        <div className="appSliderInfoContainer">
                          <div className="blue-text-1 monsterratBlack mb-1">
                            STEP {i + 1}
                          </div>
                          <div
                            className="monsterratMedium stepDesc"
                            dangerouslySetInnerHTML={{
                              __html: slide.content.desc,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="appSpacerRight  appSpacerHeight langkahSpacerApp center-vertical">
              {" "}
              <img
                className="sliderArrow  arrowRight"
                onClick={(e) => langkahNext(e)}
                src={arrow}
              ></img>
            </div>
          </div>
        </div>

        <div class=" mx-auto mitraWrapper">
          <div
            class=" monsterratExtraBold  risikoTitle f2r mb-5 blue-text-1 w50 mx-auto text-center"
            style={{ borderBottom: "1px solid black" }}
          >
            <div class="mitraText pb-5">Mitra Pilihan</div>
          </div>
          <div
            class="mitraLogoWrapper mx-auto mw90"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: 55,
            }}
          >
            <div class="center-vertical">
              {" "}
              <img class="amLogo kisiAm" src={AM_KISI}></img>
            </div>

            <div class="center-vertical">
              {" "}
              <img class="amLogo capitalAm" src={AM_CAPITAL}></img>
            </div>
            <div class="center-vertical">
              {" "}
              <img class="amLogo pinnacleAm" src={AM_PINNACLE}></img>
            </div>
            <div class="center-vertical">
              {" "}
              <img class="amLogo starAm" src={AM_STAR}></img>
            </div>
            <div class="center-vertical">
              {" "}
              <img class="amLogo avristAm" src={AM_AVRIST}></img>
            </div>
            <div class="center-vertical">
              {" "}
              <img class="amLogo insightAm" src={AM_INSIGHT}></img>
            </div>
          </div>
        </div>

        {/* <div id="container">
  <div id="top-triangle" class="mitra">Mitra Pilihan</div>
  <div id="bottom-triangle"></div>
</div>

<div class="container">
    <div class="line"></div>
    todafsdf
    sad
    ad
    sad
    df
</div> */}
        {/* <div class="monsterratExtraBold  text-center f275r my-5 blue-text-1 pb-5">
          Flow Transaksi Reksa Dana
        </div> */}
        {/* <div class="flowContainer text-center">
          <div class="flowWrapper">
            <div class="flowMiddleBorder"></div>
            <div class="mx-auto flowPadding monsterratBold blue-text-1  flowBoxBorder ">
              Order Received
            </div>
            <div class="flowSpacer"></div>
            <div class="mx-auto flowPadding monsterratBold blue-text-1  flowBoxBorder w80768">
              Instruksi order <i>subscription</i> atau <i>redemption</i> reksa
              dana dari Nasabah akan diterima oleh system back office KINDS
            </div>
            <div class="flowSpacer"></div>
            <div class="mx-auto flowPadding monsterratBold blue-text-1  flowBoxBorder ">
              Order Processed
            </div>
            <div class="flowSpacer"></div>
            <div class="flowFlexContainer">
              <div class="flowHalf halfLeft">
                <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexDiv  bg-biru-muda  center-vertical">
                  Order Nasabah akan diproses sesuai jam cut off 11.30 WIB
                </div>
                <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexContainerSpacer "></div>
              </div>
              <div class="flowHalf">
                <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexContainerSpacer "></div>
                <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexDiv  bg-biru-muda ">
                  Order Nasabah melewati jam cut off secara otomatis masuk ke
                  dalam list proses pada esok hari
                </div>
              </div>
            </div>
            <div class="flowSpacer"></div>
            <div class="mx-auto flowPadding monsterratBold blue-text-1  flowBoxBorder ">
              Temporary NAV
            </div>
            <div class="flowSpacer"></div>
            <div class="flowFlexContainer">
              <div class="flowHalf halfLeft">
                <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexDiv  bg-biru-muda  center-vertical">
                  Nasabah akan mendapatkan informasi Temporary NAV pada malam
                  hari
                </div>
                <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexContainerSpacer "></div>
              </div>
            </div>
            <div class="flowSpacer"></div>
            <div class="mx-auto flowPadding monsterratBold blue-text-1  flowBoxBorder ">
              Order Done
            </div>
            <div class="mx-auto px-5  monsterratBold blue-text-1  flowBottomSpacer "></div>
            <div class="flowBottomContainer">
              <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexDiv  bg-biru-muda  center-vertical">
                (Transaksi Subscription) Nasabah akan mendapatkan unit
                penyertaan reksa dana di dalam menu Portofolio dalam waktu
                maksimal T+7 hari bursa
              </div>
              <div class="bottomMiddleSpacer"></div>
              <div class=" px-4 py-4 monsterratBold blue-text-1  flowFlexDiv  bg-biru-muda  center-vertical">
                (Transaksi Redemption) Nasabah akan mendapatkan dana hasil
                penjualan reksa dana maksimal T+7 hari bursa
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
