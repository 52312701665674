import searchIcon from "../../assets/searchIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { host, storageLocation } from "../../config/server";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
import "moment/locale/id";
import moment from "moment";
import {
  setActiveMenu,
  setArticlePage,
  setRnaMenu,
  getResearch,
  setRnaPage,
  setIsSearchActive,
  getNews,
  setSortedNews,
  setNews,
  setIsDataFetched,
  getPromos,
} from "../../store/action";
export default function Edukasi() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isPromoFetched, setIsPromoFetched] = useState(false);  const [promoList, setPromoList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log(type.substring(1, type.length), type);
    // document.getElementById(type).className +=
    //   " subPathActive";
    dispatch(getPromos()).then((result) => {
      // console.log("gagsag", result);
      setPromoList(result);
      setIsPromoFetched(true);
      // console.log(promoList);
    });

    // switch (type) {
    //   case "reguler":
    //     document.getElementById("capping_reguler").className +=
    //       " subPathActive";
    //     document.getElementById("capping_margin").className =
    //       "subPath monsterratMedium rounded-pill mx-3";
    //     break;

    //   case "margin":
    //     // console.log("swituseG");
    //     document.getElementById("capping_margin").className += " subPathActive";
    //     document.getElementById("capping_reguler").className =
    //       "subPath monsterratMedium rounded-pill mx-3";
    //     break;

    //   default:
    //     break;
    // }
    if (window.innerWidth > 768) {
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
    }
  }, [type]);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const getData = () => {
    fetch(`${server}/api/v1/kisiNews/list`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.status) {
          console.log("reset", data);
          setData(data.data);
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <>
      <div class="promoMenuPageContainer">
        <div class="f25r blue-text-1 monsterratExtraBold my-3 text-center">
          Ada Apa di KOINS?
        </div>
        <div class="artikelSubtitle monsterratMedium grey f1r text-center mx-auto">
          Anda akan mendapatkan informasi mengenai berita pasar, promo, dan
          masih banyak lagi.
        </div>
        {isPromoFetched ? (
          <div class="promoMenuPageWrapper">
            {promoList.map((promo, i) => {
              return (
                <div
                  class={`promoMenuWrapper`}
                  onClick={(e) => {
                    if (promo.slug) {
                      navigate(`/blog/promo/${promo.slug}`);
                    } else {
                      openInNewTab(promo.ext_link);
                    }
                  }}
                >
            
                    <div
                      class="promoThumbnail"
                      // style={{
                      //   backgroundImage: `url(${
                      //     host + storageLocation + promo.thumbnail
                      //   })`,
                      // }}
                    >


                      <img src={`${host + storageLocation + promo.thumbnail}`}></img>
                    </div>
                 
                  <div class="blogTitle promoTitle monsterratSemiBold blue-text-1 f14r mt-4">
                    {promo.title}
                  </div>
                  {promo.isOffline ? (
                    <div class="monsterratMedium grey f065r mt-2">
                      {promo.info}
                    </div>
                  ) : (
                    <div class="monsterratMedium grey f065r mt-2">
                      By Admin |{" "}
                      {moment(promo.created_at).format("DD/MM/YYYY")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
        {/* <div class="promoMenuPageWrapper">
          {promoList.map((promo, i) => {
            return (
              <div
                class="promoMenuWrapper"
                onClick={(e) => navigate(`${promo.path}`)}
              >
                <img class="promoThumbnail" src={promo.img}></img>
                <div class="blogTitle promoTitle monsterratMedium grey mt-4">
                  {promo.text}
                </div>
                <div class="monsterratMedium grey f065r mt-2">
                  {promo.info}
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </>
  );
}
