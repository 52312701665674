import searchIcon from "../../assets/searchIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { setActiveMenu } from "../../store/action";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
import "moment/locale/id";
import moment from "moment"; import loading from "../../assets/loading.gif";
import {
  getNews,
  setSortedNews,
  setNews,
  setIsDataFetched,
  getPromos, setArticlePage,
} from "../../store/action";
export default function Edukasi() {
  const { type } = useParams();
  const dispatch = useDispatch();
  const articlePage = useSelector((state) => state.articlePage);
  const news = useSelector((state) => state.news); const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [data, setData] = useState([]);
  const isDataFetched = useSelector((state) => state.isDataFetched);
  useEffect(() => {
    window.scrollTo(0, 0);
    if(isDataFetched){
      responsive()
    }
    // console.log(type.substring(1, type.length), type);
    // document.getElementById(type).className +=
    //   " subPathActive";
    getData();

    // switch (type) {
    //   case "reguler":
    //     document.getElementById("capping_reguler").className +=
    //       " subPathActive";
    //     document.getElementById("capping_margin").className =
    //       "subPath monsterratMedium rounded-pill mx-3";
    //     break;

    //   case "margin":
    //     // console.log("swituseG");
    //     document.getElementById("capping_margin").className += " subPathActive";
    //     document.getElementById("capping_reguler").className =
    //       "subPath monsterratMedium rounded-pill mx-3";
    //     break;

    //   default:
    //     break;
    // }
    let cb = function (e) {
      // console.log("event happened");
      // console.log("width: ", window.innerWidth);
      // console.log("height", window.innerHeight);
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      document.getElementById("mainArticleContainer").style.height =
        window.innerWidth / 80 + "rem";
      responsive();
    };
    window.addEventListener("resize", cb);

    return () => window.removeEventListener("resize", cb);
  }, [type,isDataFetched]);
  const goToArticle = (id) => {
    navigate(`blog/artikel/${id}`);
  }; const responsive = () => {
    if (window.innerWidth > 768) {

      // console.log("sokin", document.getElementById("mainBlogTitle"));
      document.getElementById("mainArticleContainer").style.height =
        window.innerWidth / 80 + "rem";
      document.getElementById("sideImgContainer1").style.height =
        (window.innerWidth / 80) * 0.4427 + "rem";
      document.getElementById("sideImgContainer1").style.width =
        (window.innerWidth / 80) * 0.4427 + "rem";
      document.getElementById("sideImgContainer2").style.height =
        (window.innerWidth / 80) * 0.4427 + "rem";
      document.getElementById("sideImgContainer2").style.width =
        (window.innerWidth / 80) * 0.4427 + "rem";
      // console.log(
      //   document.getElementsByClassName("blogTitle"),
      //   "tayo",
      //   document.getElementsByClassName("subPath")
      // );
      const allTitle = document.getElementsByClassName("blogTitle");
      for (let i = 0; i < allTitle.length; i++) {
        const element = allTitle[i];
        element.style.fontSize = window.innerWidth * 0.00078125 + "rem";
      }
      document.getElementById("mainBlogTitle").style.fontSize =
        window.innerWidth * 0.00096354166666 + "rem";
      const allSmallImg = document.getElementsByClassName(
        "smallImageContainer"
      );
      for (let i = 0; i < allSmallImg.length; i++) {
        const element = allSmallImg[i];
        element.style.height = window.innerWidth * 0.00911458333 + "rem";
      }

      //   if (page == "promo") {
      //     const allTitle = document.getElementsByClassName("blogTitle");
      //     for (let i = 0; i < allTitle.length; i++) {
      //       const element = allTitle[i];
      //       element.style.fontSize = window.innerWidth * 0.00078125 + "rem";
      //     }
      //     const allThumbnail = document.getElementsByClassName("promoThumbnail");
      //     for (let i = 0; i < allThumbnail.length; i++) {
      //       const element = allThumbnail[i];
      //       element.style.height = window.innerWidth * 0.01302083333333 + "rem";
      //     }
      //   }
      // } else {
      //   if (page == "promo") {
      //     const allThumbnail = document.getElementsByClassName("promoThumbnail");
      //     for (let i = 0; i < allThumbnail.length; i++) {
      //       const element = allThumbnail[i];
      //       element.style.height = window.innerWidth * 0.03302083333333 + "rem";
      //     }
      //   }
    }
  };
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const getData = () => {
    fetch(`${server}/api/v1/kisiNews/list`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(file),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (data.status) {
          console.log("reset", data);
          setData(data.data);
        }
        // console.log(data, "promox");
      })

      .catch((err) => {
        // console.log(err);
      });
  };
  const getNewsHandle = (targetPage) => {
    window.scrollTo(0, 1);
    dispatch(setNews([]));
    dispatch(setArticlePage(targetPage));
    dispatch(getNews(targetPage)).then((res) => {
      // console.log(res, "blogq");
      responsive();
    });
  };
  const newsTitleFormat = (title) => {
    let result = "";

    for (let i = 0; i < title.length; i++) {
      const element = title[i];
      if (i < 51) {
        result += element;
      }
      if (i == title.length - 1) {
        result += "...";
      }
    }
    // console.log(result);

    return result;
  };
  return (
    <div className="articlePageContainer">
      {/* <div class="blogPage flex mt-5">
  <div class="px-4 blogLeft monsterratSemiBold grey w50">Home</div>
  <div class="monsterratSemiBold grey">|</div>
  <div class="px-4  monsterratSemiBold grey">Blog</div>
</div> */}
      <div class="f25r blue-text-1 monsterratExtraBold my-3 text-center">
        Ada Apa di KOINS?
      </div>
      <div class="artikelSubtitle monsterratMedium grey f1r text-center mx-auto">
        Anda akan mendapatkan informasi mengenai berita pasar, promo, dan
        masih banyak lagi.
      </div>

      {news.length > 0 ? (
        <>
          <div class="topArticleContainer">
            <div>
              <div
                title={news[0].title}
                id="mainArticleContainer"
                class="mainArticleContainer px-4"
                style={{
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${news[0].images})`,
                }}
                onClick={() => goToArticle(news[0].news_id)}
              >
                <div class="mainArticleHeading">
                  <div class="monsterratMedium f065r">{`By IQPlus | ${news[0].days_ago} | ${news[0].subject}`}</div>
                  <div
                    id="mainBlogTitle"
                    title={news[0].title}
                    class="monsterratBold"
                  >
                    {news[0].title}
                  </div>
                </div>
              </div>
            </div>
            <div class="sideContainer">
              <div
                title={news[1].title}
                class="sideArticleContainer "
                onClick={() => goToArticle(news[1].news_id)}
              >
                <div
                  id="sideImgContainer1"
                  class="sideImgContainer"
                  style={{
                    backgroundImage: `url(${news[1].images})`,
                    backgroundPosition: "0% 0%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img id="sideImg1" class="sideImg" src={news[1].image}></img> */}
                </div>
                <div class="sideNewsTitle center-vertical">
                  <div class="grey blogTitle monsterratMedium">
                    {news[1].title}
                    <div class="monsterratMedium f065r">{`By IQPlus | ${news[1].days_ago} | ${news[1].subject}`}</div>
                  </div>
                </div>
              </div>
              <div
                class="sideArticleContainer bottom-vertical-noflex"
                onClick={() => goToArticle(news[2].news_id)}
              >
                <div
                  title={news[2].title}
                  id="sideImgContainer2"
                  class="sideImgContainer "
                  style={{
                    backgroundImage: `url(${news[2].images})`,
                    backgroundPosition: "0% 0%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom",
                  }}
                >
                  {/* <img id="sideImg1" class="sideImg" src={news[1].image}></img> */}
                </div>

                <div
                  style={{ height: "100%" }}
                  class="sideNewsTitle center-vertical titleBottom"
                >
                  <div class="">
                    <div
                      title={news[2].title}
                      class="grey blogTitle monsterratMedium"
                    >
                      {news[2].title}
                      <div class="monsterratMedium f065r">{`By IQPlus | ${news[2].days_ago} | ${news[2].subject}`}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {windowSize.innerWidth > 768 ? (
            <div class="flex gridNewsContainer">
              {news.map((news, i) => {
                if (i > 2 && i < 9) {
                  return (
                    <div
                      class="gridArticleWrapper "
                      title={news.title}
                      onClick={() => goToArticle(news.news_id)}
                    >
                      <div
                        class="smallImageContainer"
                        style={{
                          backgroundImage: `url(${news.images})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div class="articleInfoContainer">
                        <div class=" monsterratMedium f075r  grey articleInfo ">
                          <div class="mt-1 monsterratMedium  blogTitle">
                            {newsTitleFormat(news.title)}
                          </div>
                          <div class="monsterratMedium f065r mt-2">{`By IQPlus | ${news.days_ago} | ${news.subject}`}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div class="flex gridNewsContainer">
              {news.map((news, i) => {
                if (i > 2 && i < 9) {
                  return (
                    <div
                      class="gridArticleWrapper "
                      title={news.title}
                      onClick={() => goToArticle(news.news_id)}
                    >
                      <div
                        class="smallImageContainer"
                        style={{
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: `url(${news.images})`,
                        }}
                      ></div>
                      <div class="articleInfoContainer">
                        <div class=" monsterratMedium f075r  grey articleInfo ">
                          <div class="mt-1 monsterratMedium  blogTitle clamp2">
                            {/* {newsTitleFormat(news.title)} */}{news.title}
                          </div>
                          <div class="monsterratMedium f065r mt-2">{`By IQPlus | ${news.days_ago} | ${news.subject}`}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </>
      ) : (
        <div class="gridArticleWrapper mx-auto">
          <div clas="mx-auto">
            <div
              class="smallImageContainer"
              style={{
                background: `url(${loading})`,
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div clas="mx-auto">
            <div
              class="smallImageContainer"
              style={{
                background: `url(${loading})`,
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <div clas="mx-auto">
            <div
              class="smallImageContainer"
              style={{
                background: `url(${loading})`,
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
        </div>
      )}

      {articlePage == 1 ? (
        <div class="text-center mx-auto pt-5" style={{ marginTop: "5rem" }}>
          <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
            «
          </button>
          <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
            ‹
          </button>
          <span class="mx-2"></span>
          <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
            1
          </button>
          <button
            onClick={() => {
              getNewsHandle(2);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            2
          </button>
          <button
            onClick={() => {
              getNewsHandle(3);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            3
          </button>
          <span class="mx-2"></span>
          <button
            onClick={() => {
              getNewsHandle(2);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            ›
          </button>
          <button class="numberButton monsterratSemiBold f12r numberButtonDisabled mx-2">
            »
          </button>
        </div>
      ) : (
        <div class="text-center mx-auto pt-5" style={{ marginTop: "5rem" }}>
          <button
            onClick={() => {
              getNewsHandle(1);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            «
          </button>
          <button
            onClick={() => {
              getNewsHandle(articlePage - 1);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            ‹
          </button>
          <span class="mx-2"></span>
          <button
            onClick={() => {
              getNewsHandle(articlePage - 1);
            }}
            class="numberButton  monsterratSemiBold f12r mx-2"
          >
            {articlePage - 1}
          </button>
          <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
            {articlePage}
          </button>
          <button
            onClick={() => {
              getNewsHandle(articlePage + 1);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            {articlePage + 1}
          </button>
          <span class="mx-2"></span>
          <button
            onClick={() => {
              getNewsHandle(articlePage + 1);
            }}
            class="numberButton monsterratSemiBold f12r mx-2"
          >
            ›
          </button>
          <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
            »
          </button>
        </div>
      )}

      <div style={{ height: "5rem" }}></div>
    </div>
  );
}
