import "./style.css";
import React, { useEffect, useState } from "react";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import waranTerstruktur from "../../../assets/waranTerstruktur.png";
import { useNavigate, useLocation } from "react-router-dom";
import Aos from "aos";
import { storageLocation } from "../../../config/server";
import faqArrow from "../../../assets/faqArrow.png";
import { server } from "../../../config/server";
import moment from "moment";
export function WaranTerstruktur({}) {
  const { state } = useLocation();
  let toAnnouncement = false;
  const [data, setData] = useState([]);
  const [dataPenerbit, setDataPenerbit] = useState([1, 2, 3, 4, 5]);
  const [newSwData, setNewSwData] = useState([]);
  const [expiredSwData, setExpiredSwData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [offset, setOffset] = useState(0);
  const [filterPenerbit, setFilterPenerbit] = useState(null);
  const [issuerList, setIssuerList] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // console.log(state);
  const filteredData = data.filter((sw) => {
    return selectedOptions.includes(sw.issuer_code.toLowerCase());
  });
  if (state) {
    toAnnouncement = state.toAnnouncement;
  }
  useEffect(() => {
    Aos.init({ duration: 2000 });
    getIssuer();
    getNewSwData();
    getExpiredSwData();
    var offsets = document.getElementById("pengumuman").getBoundingClientRect();
    var top = offsets.top;
    var left = offsets.left;
    // console.log(top);
    if (!toAnnouncement) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, top);
    }
  }, []);
  useEffect(() => {
    getData();
  }, [sortBy]);
  useEffect(() => {}, [selectedOptions]);
  useEffect(() => {
    if (window.innerWidth >= 1660) {
      const onScroll = () => setOffset(window.pageYOffset);
      // clean up code
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
      // console.log(
      //   document.documentElement.offsetHeight,
      //   "hilih",
      //   document.getElementById(`data_waran_end`).getBoundingClientRect()
      // );
      // console.log( document.getElementById(`th_waran_top`), document
      // .getElementById(`tabel_data_waran_wrapper`)
      // .getBoundingClientRect().width);
      document.getElementById(`th_waran_top`).style.width = document
        .getElementById(`tabel_data_waran_wrapper`)
        .getBoundingClientRect().width;
      document.getElementById(`th_waran_top`).style.width = document
        .getElementById(`tabel_data_waran_wrapper`)
        .getBoundingClientRect().width;
      var tableHeadTop = document.getElementById(`th_waran_top`);

      var startShow = document
        .getElementById(`th_waran_static`)
        .getBoundingClientRect();
      var navbarH = document
        .getElementById(`navBar`)
        .getBoundingClientRect().height;
      var subpathH = document
        .getElementById(`subPath`)
        .getBoundingClientRect().height;
      var tableEnd = document
        .getElementById(`data_waran_end`)
        .getBoundingClientRect();
      // console.log(startShow, navbarH, subpathH);
      tableHeadTop.style.top = `${navbarH + subpathH}px`;
      document.getElementById(`th_waran_top`).style.width = `${
        document
          .getElementById(`tabel_data_waran_wrapper`)
          .getBoundingClientRect().width
      }px`;

      if (
        startShow.top <= navbarH + subpathH &&
        tableEnd.top >
          navbarH +
            subpathH +
            document.getElementById(`th_waran_static`).getBoundingClientRect()
              .height
      ) {
        tableHeadTop.style.display = "block";

        tableHeadTop.style.top = navbarH + subpathH;
      } else {
        tableHeadTop.style.display = "none";
      }

      return () => window.removeEventListener("scroll", onScroll);

      console.log(document.documentElement.offsetHeight, "hilih");
    }
  }, [offset]);
  const getData = () => {
    fetch(`${server}/api/v2/swari/product/1${sortBy}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        setData(data.data.filter((item) => item.active == 1));
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const getIssuer = () => {
    fetch(`${server}/api/v2/swari/issuer`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        // console.log(data, "piswer");
        setIssuerList(data.data);
        let temp = [];
        for (let i = 0; i < data.data.length; i++) {
          const element = data.data[i];
          temp.push(element.issuer_code.toLowerCase());
        }
        setSelectedOptions(temp);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getNewSwData = () => {
    fetch(`${server}/api/v2/swari/termSheet/INTERNAL`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        let temp = data.data.filter((item) => item.active == 1);
        // console.log(temp, "sw_active");
        temp.push([]);

        setNewSwData(temp);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const getExpiredSwData = () => {
    fetch(`${server}/api/v2/swari/termSheet/EXPIRED`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (data) => {
        let temp = data.data.filter((item) => item.active == 1);
        // console.log(temp, "sw_active");
        temp.push([]);

        setExpiredSwData(temp);
        // console.log(data.data, "kui");
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (sortby) => {
    setSortBy(sortby);
    getData();
  };
  const CheckboxDropdown = () => {
    const options = ["CGS", "KGI", "KISI", "MAYBANK", "RHB"];
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      if (checked) {
        setSelectedOptions((prev) => [...prev, value.toLowerCase()]);
      } else {
        setSelectedOptions((prev) =>
          prev.filter((option) => option.toLowerCase() !== value.toLowerCase())
        );
      }
    };

    const handleSelectAll = (event) => {
      if (event.target.checked) {
        let temp = [];
        for (let i = 0; i < issuerList.length; i++) {
          const element = issuerList[i];
          temp.push(element.issuer_code.toLowerCase());
        }
        setSelectedOptions(temp);
      } else {
        setSelectedOptions([]);
      }
    };

    const toggleDropdown = () => {
      setDropdownOpen((prev) => !prev);
    };

    return (
      <div
        className="dropdown monsterratMedium mb-4 mx-auto"
        style={{ borderRadius: 4 }}
      >
        <button
          className="dropdown-button flex justify-content-between center-vertical text-center w100"
          style={{ borderRadius: 4, padding: 0, paddingLeft: 30, height: 40 }}
        >
          <div class="w100" style={{ textTransform: "uppercase" }}>
            {selectedOptions.length > 0
              ? selectedOptions.join(", ")
              : "Penerbit"}
          </div>
          <div
            class="pointer blue-text-1 center-vertical px-2"
            onClick={toggleDropdown}
            style={{ borderLeft: "1px solid #868686", height: 40 }}
          >
            ▼
          </div>
        </button>
        {dropdownOpen && (
          <div className="dropdown-content">
            <div>
              <input
                type="checkbox"
                checked={selectedOptions.length === issuerList.length}
                onChange={handleSelectAll}
              />
              <span class="mx-4 monsterratRegular"> Semua Penerbit</span>
            </div>
            {issuerList.map((option) => (
              <div key={option}>
                <input
                  type="checkbox"
                  value={option.issuer_code.toLowerCase()}
                  checked={selectedOptions.includes(
                    option.issuer_code.toLowerCase()
                  )}
                  onChange={handleCheckboxChange}
                />
                <span class="mx-4 monsterratRegular">
                  {" "}
                  {option.issuer_code.toUpperCase()}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US";
    }

    return "https://apps.apple.com/id/app/kisi-mobile/id1493151464";
  };
  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      <div class="syariahPageContainer">
        <div class="">
          {/* <div class="syariahImgContainer">
            <img class="syariahImg" src={koinsSyariah}></img>
          </div> */}
          <div class="etfContainer productBanner">
            <div class="etfTextContainer ">
              <div class="etfTextWrapper">
                <div class="monsterratBlack f4r bannerTitle grey  nowrap blue-text-1">
                  Waran<br></br>Terstruktur<br></br>
                  <span
                    style={{
                      color: "rgb(251, 176, 58)",
                    }}
                  >
                    (SW)
                  </span>
                  <br class="brMobile"></br>
                </div>
                {/* <div
                  class="monsterratBlack f4r bannerTitle grey  blue-text-1"
               
                >
                  1 LOT / ETF
                </div> */}
                <div class="monsterratMedium bannerSubtitle  mt-4 grey kisiMobileSmallText f12r">
                  PT Korea Investment And Sekuritas Indonesia<br></br> sebagai
                  Penyedia Waran Terstruktur
                </div>

                <div class="downloadButtonGroup etfDownloadGroup">
                  <div class=" etfDownloadButtonContainer">
                    <button
                      class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                      onClick={() => navigate("/kisimobile")}
                    >
                      DOWNLOAD SEKARANG
                    </button>

                    <div class="flex mobileMarketplaceContainer">
                      <div class="  flexEnd appStore">
                        <img
                          class="mobileMarketplace"
                          src={appStore}
                          onClick={() =>
                            openInNewTab(
                              "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                            )
                          }
                        />
                      </div>
                      <div class="  flexStart playStore">
                        <img
                          class="mobileMarketplace"
                          src={playStore}
                          onClick={() =>
                            openInNewTab(
                              "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="monsterratRegular grey disclaimerText mt-5">
                    *Selain Nasabah Bank BCA & CIMB Niaga, Hubungi Customer
                    Service Kami
                  </div>
                </div>
              </div>
            </div>

            <div class="waranImgContainer">
              <img
                class="etfImg"
                src={waranTerstruktur}
                onLoad={() => {
                  setImageLoaded(true);
                }}
                data-aos="fade-up"
                data-aos-duration="3000"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="reksadanaUpperContainer mb-5">
        <div class="monsterratExtraBold  f275r mb-3 blue-text-1">
          Daftar Waran Terstruktur
        </div>
        {/* <div class="monsterratMedium mt-2 f15r blue-text-1">
          Fund by KISI Mobile
        </div> */}
        <div class="monsterratMedium reksadanaDescriptionTxt grey jenisAkunText mt-3 f1r w80">
          Pilihan Waran Terstruktur untuk solusi investasi anda
        </div>
        {/* <div class="monsterratMedium grey jenisAkunText mt-2 f1r">
          Untuk dapat melakukan transaksi Reksa Dana silahkan download Aplikasi
          KISI Mobile
        </div> */}
      </div>
      {/* <FollowSocmed /> */}
      <div class="mx-auto flex justify-content-center">
          <CheckboxDropdown />
        </div>
      <div
        class="w90 mx-auto mb-5 pb-5  "
        id="tabel_data_waran_wrapper"
        style={{ overflow: "auto" }}
      >
        {/* <div>
          <select
            onChange={(e) => {}}
            className="form-select subPathSelect monsterratMedium f12r"
            aria-label="Default select example"
            style={{ width: 400 }}
          >
            {dataPenerbit.map((product, i) => {
              if (filterPenerbit == product.id) {
                return (
                  <option selected className="subPathOption">
                    <label>
                      <input
                        type="checkbox"
                        // checked={isChecked}
                        // onChange={handleChange}
                      />
                      Check me!
                    </label>
                  </option>
                );
              } else {
                return (
                  <option className="subPathOption">
                    <input
                      type="checkbox"
                      value="checkbox"
                      class="son_check_two"
                    />
                  </option>
                );
              }
            })}
          </select>
        </div> */}
       
        <table
          id="tabel_data_waran_1"
          className="table  table-striped table-warrant"
          style={{ textAlign: "center", borderLeft: "0px solid transparent" }}
        >
          <thead
            id="th_waran_top"
            style={{
              border: "none !important  ",
              position: "fixed",
              display: "none",
              // width: "90%",
              zIndex: 100,
              overflow: "auto",
              cursor: "context-menu",
            }}
          >
            <tr
              className="h1"
              style={{
                borderTop: "1px solid transparent",
              }}
            >
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 blue-text-1"
                colSpan="13"
                style={{
                  borderStyle: "none",
                  backgroundColor: "#eef9ff",
                  borderTopLeftRadius: 20,
                }}
              >
                Data Waran Terstruktur
              </th>
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 white px-4"
                colSpan="2"
                style={{
                  backgroundColor: "#0067b1",
                  // borderBottom: "1px solid white",
                  whiteSpace: "nowrap",
                  borderTopRightRadius: 20,
                }}
              >
                Market Data
              </th>
            </tr>

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className=" blue-border monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical justify-content-around"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Nama Waran</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/warrant_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/warrant_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical ">
                  <span>Penerbit</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/issuer_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/issuer_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical h100 w100">
                  <span>Saham Dasar</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Call/Put</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>
                    Harga <i>Underlying</i>(Rp)
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Harga Pelaksanaan</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_price/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_price/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Rasio Pelaksanaan</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Tanggal Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/expiry_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/expiry_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>
                    Tanggal <i>Trading</i> Terakhir
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Periode Tersisa Hingga Kadaluwarsa(Hari)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/maturity_days/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/maturity_days/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Sensitivitas Waran(Tick)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Gearing(x)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Volatilitas Tersirat(%)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/implied_volatility/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/implied_volatility/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran monsterratSemiBold white light"
                style={{
                  backgroundColor: "#0071bf",
                  borderRight: "1px solid white",
                }}
              >
                <div class="flex center-vertical">
                  <span>
                    Harga <i>Bid</i>(Rp)
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_BidPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_BidPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>
              <th
                className="th_waran monsterratSemiBold white "
                style={{
                  backgroundColor: "#0071bf",
                  borderRight: "0px solid transparent",
                  borderLeft: "1px solid white !important",
                }}
              >
                <div class="flex center-vertical">
                  <span>
                    Harga <i>Ask</i>(Rp)
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_OfferPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_OfferPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>
              {/* Repeat the same structure for other table headers */}
            </tr>
          </thead>

          <thead
            id="th_waran_static"
            style={{
              border: "none !important  ",
            }}
          >
            <tr
              className="h1"
              style={{
                borderTop: "1px solid transparent",
              }}
            >
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 blue-text-1"
                colSpan="13"
                style={{
                  borderStyle: "none",
                  backgroundColor: "#eef9ff",
                  borderTopLeftRadius: 20,
                }}
              >
                Data Waran Terstruktur
              </th>
              <th
                className="tableWarrantHeaderFont monsterratBold py-4 white px-4"
                colSpan="2"
                style={{
                  backgroundColor: "#0067b1",
                  // borderBottom: "1px solid white",
                  whiteSpace: "nowrap",
                  borderTopRightRadius: 20,
                }}
              >
                Market Data
              </th>
            </tr>

            <tr
              className="h2 tableWarranFontContent"
              style={{
                textAlign: "left",
                borderLeft: "0px solid transparent",
                borderRight: "0px solid transparent",
              }}
            >
              <th
                className=" blue-border monsterratBold  justify-content-around blue-text-1 fixedTableCol"
                style={{
                  backgroundColor: "#eef9ff",
                  borderLeft: "0px solid transparent",
                  borderRight: "1px solid red !important",
                  verticalAlign: "middle",
                }}
              >
                <div
                  class="flex center-vertical justify-content-around"
                  style={{ verticalAlign: "middle" }}
                >
                  <span>Nama Waran</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/warrant_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/warrant_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1  noLeftBorder"
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical ">
                  <span>Penerbit</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/issuer_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/issuer_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical h100 w100">
                  <span>Saham Dasar</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/base_stock_code/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Call/Put</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/PutOrCall/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>
                    Harga <i>Underlying</i>(Rp)
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/BaseStock_LastPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Harga Pelaksanaan</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_price/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_price/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Rasio Pelaksanaan</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/exercise_ratio/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Tanggal Jatuh Tempo</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/expiry_date/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        handleSort("/expiry_date/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>
                    Tanggal <i>Trading</i> Terakhir
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/lastTradingDate/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Periode Tersisa Hingga Kadaluwarsa(Hari)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/maturity_days/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/maturity_days/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Sensitivitas Waran(Tick)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/warrant_sensitivity_ticks/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Gearing(x)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/effective_gearing/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran blue-border monsterratBold blue-text-1 "
                style={{ backgroundColor: "#eef9ff" }}
              >
                <div class="flex center-vertical">
                  <span>Volatilitas Tersirat(%)</span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/implied_volatility/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/implied_volatility/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>{" "}
              <th
                className="th_waran monsterratSemiBold white light"
                style={{
                  backgroundColor: "#0071bf",
                  borderRight: "1px solid white",
                }}
              >
                <div class="flex center-vertical">
                  <span>
                    Harga <i>Bid</i>(Rp)
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_BidPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_BidPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>
              <th
                className="th_waran monsterratSemiBold white "
                style={{
                  backgroundColor: "#0071bf",
                  borderRight: "0px solid transparent",
                  borderLeft: "1px solid white !important",
                }}
              >
                <div class="flex center-vertical">
                  <span>
                    Harga <i>Ask</i>(Rp)
                  </span>
                  <span
                    style={{
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_OfferPrice/ASC");
                      }}
                    >
                      ▲
                    </div>
                    <div
                      class="pointer"
                      onClick={(e) => {
                        setSortBy("/Warrant_OfferPrice/DESC");
                      }}
                    >
                      ▼
                    </div>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody id="formData tableWarrantFontContent">
            {filteredData.map((data, i) => {
              return (
                <tr className="form_c" id={`data_waran_${i}`}>
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a>{data.warrant_code}</a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.issuer_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.base_stock_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.PutOrCall}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.BaseStock_LastPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_price}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_ratio}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.expiry_date ? (
                      moment(data.expiry_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {moment(data.lastTradingDate).format("DD-MMM-YYYY")}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.maturity_days}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.warrant_sensitivity_ticks}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.effective_gearing}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.implied_volatility}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.Warrant_BidPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.Warrant_OfferPrice}
                  </td>

                  {/* Repeat the same structure for other table data cells */}
                </tr>
              );
            })}
            {[[]].map((data, i) => {
              return (
                <tr className="form_c" id="data_waran_end">
                  <td
                    className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol"
                    style={{ borderLeft: "1px solid transparent !important" }}
                  >
                    <a
                      onClick={() =>
                        this.getRicToDetails("KISIDRCJ4A", "KISIDRCJ4A")
                      }
                    >
                      {data.warrant_code}
                    </a>
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold tdRhb noLeftBorder">
                    {" "}
                    {data.issuer_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.base_stock_code}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {" "}
                    {data.PutOrCall}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.BaseStock_LastPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_price}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.exercise_ratio}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb nowrap">
                    {data.expiry_date ? (
                      moment(data.expiry_date).format("DD-MMM-YYYY")
                    ) : (
                      <div style={{ color: "transparent" }}>I</div>
                    )}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.maturity_days}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.warrant_sensitivity_ticks}{" "}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.effective_gearing}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.implied_volatility}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.Warrant_BidPrice}
                  </td>
                  <td className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb">
                    {data.Warrant_OfferPrice}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <div className="monsterratExtraBold f2r mx-auto  mw70768 grey textCenter blue-text-1 f2r mt-5 pt-5">
          Daftar Waran Terbaru
        </div>
        <div className="monsterratMedium f1r  mw70768 grey textCenter  mb-4 sliderSmall">
          Pilihan Waran Terstruktur terbaru yang dikeluarkan oleh KISI
        </div>
        <div class="pengumumanTabelWaran">
          {" "}
          <div class="w90 mx-auto mb-5 pb-5 " style={{ overflow: "auto" }}>
            <table
              id="searchForm"
              className="table  table-striped table-warrant  mx-auto"
              style={{
                textAlign: "center",
                maxWidth: 1200,
              }}
            >
              <thead style={{}}>
                <tr className="h2 tableWarranFontContent" style={{}}>
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderTopLeftRadius: 20,
                    }}
                  >
                    Nama Waran
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderLeft: "1px solid white",
                      borderRight: "1px solid white",
                    }}
                  >
                    Saham Dasar
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Tanggal Pencatatan
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Tipe Opsi
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Harga Pelaksanaan
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Rasio Pelakasanaan
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto th_waran blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Tanggal Kadaluwarsa
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto th_waran blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderLeft: "1px solid white",
                      borderTopRightRadius: 20,
                    }}
                  >
                    <i>Term Sheet</i>
                  </th>{" "}
                </tr>
              </thead>
              <tbody id="formData tableWarrantFontContent">
                {newSwData.map((data, i) => {
                  return (
                    <tr className="form_c">
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {data.warrant_code}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1  "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {data.base_stock_code}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1  "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <>
                            {" "}
                            {moment(data.publish_date).format("DD-MMM-YYYY")}
                          </>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1  "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {data.PutOrCall}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1  "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> IDR {data.exercise_price}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td className="tableWarranFontContent  monsterratSemiBold tdRhb noLeftBorder text-center ">
                        {data.warrant_code ? (
                          <> {data.exercise_ratio}</>
                        ) : (
                          <span class="hidden">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb  text-center"
                        style={{
                          borderLeft: "1px solid white",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {moment(data.expiry_date).format("DD-MMM-YYYY")}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb  text-center"
                        style={{
                          borderLeft: "1px solid white",
                        }}
                      >
                        {data.file ? (
                          <>
                            {" "}
                            <button
                              id="faqBackButton"
                              class=" monsterratMedium blue-btn rounded-pill flex  grey   mx-auto px-2  center-vertical "
                              onClick={(e) => {
                                openInNewTab(
                                  `${server}${storageLocation}${data.file}`
                                );
                              }}
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #787878",
                                transition: "0s",
                              }}
                            >
                              <span
                                style={{ marginRight: "0.3rem" }}
                                class="mr-1"
                              >
                                Unduh
                              </span>{" "}
                              <img
                                style={{
                                  marginRight: "0rem",
                                  transform: "rotate(360deg)",
                                  height: "0.5rem",
                                }}
                                src={faqArrow}
                                class="faqBackArrow center-vertical"
                              ></img>
                            </button>
                          </>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="monsterratExtraBold f2r mx-auto  mw70768 grey textCenter blue-text-1 f2r">
          Daftar Waran Jatuh Tempo
        </div>
        <div className="monsterratMedium f1r  mw70768 grey textCenter  mb-4 sliderSmall">
          Menampilkan Waran Terstruktur kedaluwarsa yang dikeluarkan oleh KISI
        </div>
        <div class="pengumumanTabelWaran">
          {" "}
          <div class="w90 mx-auto " style={{ overflow: "auto" }}>
            <table
              id="searchForm"
              className="table  table-striped table-warrant  mx-auto"
              style={{
                textAlign: "center",
                maxWidth: 1200,
              }}
            >
              <thead style={{}}>
                <tr className="h2 tableWarranFontContent" style={{}}>
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderTopLeftRadius: 20,
                    }}
                  >
                    Nama Waran
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderLeft: "1px solid white",
                      borderRight: "1px solid white",
                    }}
                  >
                    Saham Dasar
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Tanggal Pencatatan
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Tipe Opsi
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Harga Pelaksanaan
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderRight: "1px solid white",
                    }}
                  >
                    Rasio Pelakasanaan
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto th_waran blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",
                    }}
                  >
                    Tanggal Kadaluwarsa
                  </th>{" "}
                  <th
                    className="py-4 white text-center mx-auto th_waran blue-border monsterratBold  fixedTableCol"
                    style={{
                      backgroundColor: "#0271bf",

                      borderLeft: "1px solid white",
                      borderTopRightRadius: 20,
                    }}
                  >
                    <i>Term Sheet</i>
                  </th>{" "}
                </tr>
              </thead>
              <tbody id="formData tableWarrantFontContent">
                {expiredSwData.map((data, i) => {
                  return (
                    <tr className="form_c">
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {data.warrant_code}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {data.base_stock_code}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <>
                            {" "}
                            {moment(data.publish_date).format("DD-MMM-YYYY")}
                          </>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {data.PutOrCall}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold search-td-h noLeftBorder blue-text-1 fixedTableCol "
                        style={{
                          borderRight: "1px solid transparent ",
                          borderLeft: "1px solid white ",
                          color: "black",
                        }}
                      >
                        {data.warrant_code ? (
                          <> IDR {data.exercise_price}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      <td className="tableWarranFontContent  monsterratSemiBold tdRhb noLeftBorder text-center ">
                        {data.warrant_code ? (
                          <> {data.exercise_ratio}</>
                        ) : (
                          <span class="hidden">|</span>
                        )}
                      </td>
                      <td
                        className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb  text-center"
                        style={{
                          borderLeft: "1px solid white",
                        }}
                      >
                        {data.warrant_code ? (
                          <> {moment(data.expiry_date).format("DD-MMM-YYYY")}</>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>{" "}
                      <td
                        className="tableWarranFontContent monsterratSemiBold c_one_1 tdRhb  text-center"
                        style={{
                          borderLeft: "1px solid white",
                        }}
                      >
                        {data.file ? (
                          <>
                            {" "}
                            <button
                              id="faqBackButton"
                              class=" monsterratMedium blue-btn rounded-pill flex  grey   mx-auto px-2  center-vertical "
                              onClick={(e) => {
                                openInNewTab(
                                  `${server}${storageLocation}${data.file}`
                                );
                              }}
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #787878",
                                transition: "0s",
                              }}
                            >
                              <span
                                style={{ marginRight: "0.3rem" }}
                                class="mr-1"
                              >
                                Unduh
                              </span>{" "}
                              <img
                                style={{
                                  marginRight: "0rem",
                                  transform: "rotate(360deg)",
                                  height: "0.5rem",
                                }}
                                src={faqArrow}
                                class="faqBackArrow center-vertical"
                              ></img>
                            </button>
                          </>
                        ) : (
                          <span class="hidden px-2 ">|</span>
                        )}
                      </td>
                      {/* Repeat the same structure for other table data cells */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="faqDiv" style={{ marginTop: "1rem" }}>
        <div className="faqText" style={{ marginBottom: 0 }}>
          <div
            id="pengumuman"
            className="monsterratExtraBold f2r mx-auto  mw70768 grey textCenter blue-text-1 f2r"
          >
            Pengumuman
          </div>
          <div className="monsterratMedium f12r  mw70768 grey textCenter mt-2 mb-4 sliderSmall"></div>
        </div>
        <div
          id="subPath"
          className="subPathWrapper  productSubPath "
          style={{ paddingTop: "3rem" }}
        >
          <div className="subPathContainer">
            <div
              className="subPath subPathProduk monsterratMedium rounded-pill "
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="saham"
              onClick={() =>
                navigate("/produk/waran-terstruktur/pengumuman/penerbitan")
              }
            >
              Penerbitan
            </div>
            <div
              className="subPath subPathProduk monsterratMedium rounded-pill "
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="margin"
              onClick={() =>
                navigate("/produk/waran-terstruktur/pengumuman/kadaluwarsa")
              }
            >
              Kadaluwarsa
            </div>

            <div
              className="subPath subPathProduk monsterratMedium rounded-pill "
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="syariah"
              onClick={() =>
                navigate("/produk/waran-terstruktur/pengumuman/perubahan")
              }
            >
              Perubahan
            </div>
            <div
              className="subPath subPathProduk monsterratMedium rounded-pill "
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="reksadana"
              onClick={() =>
                navigate("/produk/waran-terstruktur/pengumuman/penyelesaian")
              }
            >
              Penyelesaian
            </div>
            <div
              className="subPath subPathProduk monsterratMedium rounded-pill "
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="etf"
              onClick={() =>
                navigate("/produk/waran-terstruktur/dokumen/prospektus-dasar")
              }
            >
              Dokumen Waran
            </div>
            <div
              className="subPath subPathProduk monsterratMedium rounded-pill "
              style={{
                width: "14%",
                fontSize: 12,
              }}
              id="waran-terstruktur"
              onClick={() =>
                navigate("/produk/waran-terstruktur/pengumuman/etc")
              }
            >
              Lain-Lain
            </div>
          </div>
        </div>
      </div>
      <div className="faqDiv">
        <div className="faqText">
          <div className="monsterratExtraBold f2r mx-auto  mw70768 grey textCenter blue-text-1 f2r">
            Panduan Waran Terstruktur
          </div>
          <div className="monsterratMedium f12r  mw70768 grey textCenter mt-2 mb-4 sliderSmall">
            Penjelasan Umum Terkait Waran Terstruktur
          </div>
        </div>
        <div className="faqContainer">
          <div className="waranFaqWrapper">
            <div className="faqTitle waranFaqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion f12r">
                  Apa itu Waran Terstruktur?
                </div>
              </div>
              <button
                class="px-4 py-1 rounded-corner monsterratMedium f09r grey hoverBlue"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #707070",
                }}
                onClick={(e) => navigate("/produk/waran-terstruktur/faq/q1")}
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>{" "}
        <div className="faqContainer">
          <div className="waranFaqWrapper">
            <div className="faqTitle waranFaqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion f12r">
                  Kenapa Transaksi Waran Terstruktur?
                </div>
              </div>
              <button
                class="px-4 py-1 rounded-corner monsterratMedium f09r grey hoverBlue"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #707070",
                }}
                onClick={(e) => navigate("/produk/waran-terstruktur/faq/q2")}
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>{" "}
        <div className="faqContainer">
          <div className="waranFaqWrapper">
            <div className="faqTitle waranFaqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion f12r">
                  Bagaimana Harga Waran Terstruktur dibentuk?
                </div>
              </div>
              <button
                class="px-4 py-1 rounded-corner monsterratMedium f09r grey hoverBlue"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #707070",
                }}
                onClick={(e) => navigate("/produk/waran-terstruktur/faq/q3")}
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>{" "}
        <div className="faqContainer">
          <div className="waranFaqWrapper">
            <div className="faqTitle waranFaqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion f12r">
                  Pengertian dalam Waran Terstruktur
                </div>
              </div>
              <button
                class="px-4 py-1 rounded-corner monsterratMedium f09r grey hoverBlue"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #707070",
                }}
                onClick={(e) => navigate("/produk/waran-terstruktur/faq/q4")}
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>{" "}
        <div className="faqContainer">
          <div className="waranFaqWrapper">
            <div className="faqTitle waranFaqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion f12r">
                  Risiko Waran Terstruktur
                </div>
              </div>
              <button
                class="px-4 py-1 rounded-corner monsterratMedium f09r grey hoverBlue"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #707070",
                }}
                onClick={(e) => navigate("/produk/waran-terstruktur/faq/q5")}
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>{" "}
        <div className="faqContainer">
          <div className="waranFaqWrapper">
            <div className="faqTitle waranFaqTitle ">
              <div className="center-vertical">
                <div className=" monsterratBold grey faqQuestion f12r">
                  Regulasi Waran Terstruktur
                </div>
              </div>
              <button
                class="px-4 py-1 rounded-corner monsterratMedium f09r grey hoverBlue"
                style={{
                  backgroundColor: "white",
                  border: "2px solid #707070",
                }}
                onClick={(e) => navigate("/produk/waran-terstruktur/faq/q6")}
              >
                Selengkapnya
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
