import searchIcon from "../../assets/searchIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState,useRef } from "react";
import { host, storageLocation } from "../../config/server";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../config/server";
import "moment/locale/id";
import moment from "moment";
import {
    setActiveMenu,
    setArticlePage,
    setRnaMenu,
    getResearch,
    setRnaPage,
    setIsSearchActive,
} from "../../store/action";
import download from "../../assets/download.png";
export default function Edukasi() {
    const elementRef = useRef(null);  // Create a ref
    const [height, setHeight] = useState(0);  // State to store the height
    const { type } = useParams();
    const dispatch = useDispatch();
    const rnaMenu = useSelector((state) => state.rnaMenu);
    const research_list = useSelector((state) => state.research_list);
    const rnaPage = useSelector((state) => state.rnaPage);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (elementRef.current) {
            // Get the height of the referenced element
            setHeight(elementRef.current.getBoundingClientRect().top+200);
            console.log(elementRef.current.getBoundingClientRect().top,'heitt');
            
          }
        window.scrollTo(0, 0);
        let allSubPath = document.getElementsByClassName("rnaSubPath");
      for (let i = 0; i < allSubPath.length; i++) {
        const element = allSubPath[i];
        // console.log(element.className);
        element.className = "subPath monsterratMedium rounded-pill mx-4 ";
      }
      document.getElementById(rnaMenu).className += " subPathActive";
        // console.log(type.substring(1, type.length), type);
        // document.getElementById(type).className +=
        //   " subPathActive";
        dispatch(getResearch());

        // switch (type) {
        //   case "reguler":
        //     document.getElementById("capping_reguler").className +=
        //       " subPathActive";
        //     document.getElementById("capping_margin").className =
        //       "subPath monsterratMedium rounded-pill mx-3";
        //     break;

        //   case "margin":
        //     // console.log("swituseG");
        //     document.getElementById("capping_margin").className += " subPathActive";
        //     document.getElementById("capping_reguler").className =
        //       "subPath monsterratMedium rounded-pill mx-3";
        //     break;

        //   default:
        //     break;
        // }
        if (window.innerWidth > 768) {
            document.getElementById("navBar").style.boxShadow =
                "0px 4px 14px rgb(0 0 0 / 7%)";
        }
    }, [type,rnaMenu]);
    const rnaPagehandle = (page) => {
window.scrollTo(0, height);
        dispatch(setRnaPage(page));
    };
    const navigate = useNavigate();
    const openInNewTab = (url) => {
        var win = window.open(url, "_blank");
        win.focus();
    }; const handleSetRnaMenu = (par) => {
        dispatch(setRnaPage(1));
        dispatch(setRnaMenu(par));
    };
    const filterBy = (rna) => {
        // console.log(rna.type.toLowerCase(),rnaMenu);
        if (rna.type) {
            return rna.type.toLowerCase() == rnaMenu;
        } else {
            return rna;
        }
    };
    const RnaTable = () => {
        return (
            <div class=" mh20r ">
                <table
                    className="table table-bordered  table-striped dataTable no-footer monsterratMedium  rnaTable"
                    id="datatable"
                >
                    <thead>
                        <tr role="row" class="rnaRow">
                            <th
                                class="sorting namaRow px-5"
                                tabindex="0"
                                aria-controls="datatable"
                                rowspan="1"
                                colspan="1"
                                aria-label="NAMA BERKAS: activate to sort column ascending"
                            >
                                NAMA BERKAS
                            </th>
                            <th
                                class="sorting_desc tanggalRow text-center"
                                tabindex="0"
                                aria-controls="datatable"
                                rowspan="1"
                                colspan="1"
                                aria-sort="descending"
                                aria-label="TANGGAL: activate to sort column ascending"
                            >
                                TANGGAL
                            </th>
                            <th
                                class="sorting downloadRow text-center"
                                tabindex="0"
                                aria-controls="datatable"
                                rowspan="1"
                                colspan="1"
                                aria-label="UNDUH: activate to sort column ascending"
                            >
                                UNDUH
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {JSON.stringify(research_list)} */}
                        {research_list
                            .sort(compareDate)
                            .filter(filterBy)
                            .slice((rnaPage - 1) * 10, rnaPage * 10)
                            .map((rna, i) => {
                                return (
                                    <>
                                        <tr role="row" class="odd" key={i}>
                                            <td class="px-">{rna.title}</td>
                                            <td class="sorting_1 text-center">
                                                {moment(rna.date).format("DD/MM/YYYY")}
                                            </td>
                                            <td class="text-center">
                                                <a
                                                    href={`${host}${storageLocation}${JSON.parse(rna.document_file)[0].download_link
                                                        }`}
                                                    target="_blank"
                                                >
                                                    <img class="downloadImgRiset" src={download} />
                                                </a>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        );
    };
    function compareDate(a, b) {
        if (a.date > b.date) {
            return -1;
        }
        if (a.date < b.date) {
            return 1;
        }
        return 0;
    }
    const getData = () => {
        fetch(`${server}/api/v1/kisiNews/list`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            // mode: "cors", // no-cors, *cors, same-origin
            // body: JSON.stringify(file),
            headers: {
                "Content-Type": "application/json",
                // access_token: localStorage.getItem("access_token"),
            },
        })
            .then((res) => res.json())
            .then(async (data) => {
                if (data.status) {
                    console.log("reset", data);
                    setData(data.data);
                }
                // console.log(data, "promox");
            })

            .catch((err) => {
                // console.log(err);
            });
    };
    return (
        <div>
            <div id="rnaPath" class="subPathWrapperpb-5">
                <div class="subPathContainer appPageLocation">
                    <div
                        className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
                        id="daily"
                        onClick={() => handleSetRnaMenu("daily")}
                    >
                        Daily Update
                    </div>
                    <div
                        className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
                        id="weekly"
                        onClick={() => handleSetRnaMenu("weekly")}
                    >
                        Weekly Update
                    </div>
                    <div
                        className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
                        id="monthly"
                        onClick={() => handleSetRnaMenu("monthly")}
                    >
                        Research Company
                    </div>
                    <div
                        className="subPath rnaSubPath monsterratMedium rounded-pill mx-3"
                        id="yearly"
                        onClick={() => handleSetRnaMenu("yearly")}
                    >
                        Strategy Update
                    </div>
                </div>
            </div>
            <div class="w60 mx-auto mt-5">
                <div ref={elementRef}class="f25r  mb-4 blue-text-1 monsterratExtraBold">
                    Riset dan Analisa Bulanan
                </div>
                <div class="monsterratMedium grey f1r">
                    Dengan menggunakan teknologi untuk membantu investor
                    memvisualisasikan data keuangan, kami memungkinkan pelanggan kami
                    untuk menganalisa pasar, mengamati, dan mengelola portofolio mereka
                    dengan lebih baik, menemukan saham dan dana baru yang menjanjikan
                    untuk dibeli, dan akhirnya membuat keputusan investasi cerdas dan
                    tepat waktu. Untuk membantu Anda mempelajari lebih lanjut tentang
                    tren saham, kami telah menyediakan dokumen analisis yang bermanfaat.
                </div>
            </div>
            <RnaTable />
            {rnaPage == 1 ? (
                <div class="text-center mx-auto pt-5">
                    <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                        «
                    </button>
                    <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                        ‹
                    </button>
                    <span class="mx-2"></span>
                    <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
                        1
                    </button>
                    {research_list.sort(compareDate).filter(filterBy).length < 11 ? (
                        <>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                2
                            </button>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                3
                            </button>
                            <span class="mx-2"></span>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                ›
                            </button>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                »
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => {
                                    rnaPagehandle(2);
                                }}
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                2
                            </button>
                            <button
                                onClick={() => {
                                    rnaPagehandle(3);
                                }}
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                3
                            </button>
                            <span class="mx-2"></span>
                            <button
                                onClick={() => {
                                    rnaPagehandle(2);
                                }}
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                ›
                            </button>
                            <button
                                onClick={() =>
                                    rnaPagehandle(
                                        Math.floor(
                                            research_list.sort(compareDate).filter(filterBy)
                                                .length /
                                            10 +
                                            1
                                        )
                                    )
                                }
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                »
                            </button>
                        </>
                    )}
                </div>
            ) : (
                <div class="text-center mx-auto pt-5">
                    <button
                        onClick={() => {
                            rnaPagehandle(1);
                        }}
                        class="numberButton monsterratSemiBold f12r mx-2"
                    >
                        «
                    </button>
                    <button
                        onClick={() => {
                            rnaPagehandle(rnaPage - 1);
                        }}
                        class="numberButton monsterratSemiBold f12r mx-2"
                    >
                        ‹
                    </button>
                    <span class="mx-2"></span>
                    <button
                        onClick={() => {
                            rnaPagehandle(rnaPage - 1);
                        }}
                        class="numberButton  monsterratSemiBold f12r mx-2"
                    >
                        {rnaPage - 1}
                    </button>
                    <button class="numberButton numberButtonActive monsterratSemiBold f12r mx-2">
                        {rnaPage}
                    </button>

                    {research_list
                        .sort(compareDate)
                        .filter(filterBy)
                        .slice((rnaPage - 1) * 10, rnaPage * 10).length < 10 ? (
                        <>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                {rnaPage + 1}
                            </button>
                            <span class="mx-2"></span>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                ›
                            </button>
                            <button class="numberButton numberButtonDisabled monsterratSemiBold f12r mx-2">
                                »
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => {
                                    rnaPagehandle(rnaPage + 1);
                                }}
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                {rnaPage + 1}
                            </button>
                            <span class="mx-2"></span>
                            <button
                                onClick={() => {
                                    rnaPagehandle(rnaPage + 1);
                                }}
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                ›
                            </button>
                            <button
                                onClick={() => {
                                    // console.log(Math.floor(research_list));
                                    // console.log(
                                    //   research_list.sort(compareDate).filter(filterBy).length
                                    // );

                                    rnaPagehandle(
                                        Math.floor(
                                            research_list.sort(compareDate).filter(filterBy)
                                                .length /
                                            10 +
                                            1
                                        )
                                    );
                                }}
                                class="numberButton monsterratSemiBold f12r mx-2"
                            >
                                »
                            </button>
                        </>
                    )}
                </div>
            )}
            <div style={{ height: "9vh" }}></div>
        </div>
    );
}
